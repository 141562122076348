
import Popup from "./DBAForm";
import { useState } from "react";
import Posterimg from "../../assets/Ad_Poster/Ad_Poster03.jpg";

interface FormData {
  fullName: string;
  email: string;
  countryCode: string;
  mobile: string;
  agreed: boolean;
}

const DBAProgram = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [isSubmitting] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    countryCode: "+91",
    mobile: "",
    agreed: false,
  });
  const [emailError, setEmailError] = useState<string>("");
  const [mobileError, setMobileError] = useState<string>("");

  const countryCodes = [
    { code: "+91", country: "IN" },
    { code: "+1", country: "US" },
    { code: "+44", country: "UK" },
    { code: "+61", country: "AU" },
    { code: "+86", country: "CN" },
    { code: "+81", country: "JP" },
    { code: "+49", country: "DE" },
    { code: "+33", country: "FR" },
  ];

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email) || !email.endsWith(".com")) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validateMobile = (mobile: string, countryCode: string) => {
    const numericRegex = /^\d+$/;
    if (!numericRegex.test(mobile)) {
      setMobileError("Only numeric characters are allowed");
      return false;
    }

    if (countryCode === "+91") {
      if (mobile.length !== 10) {
        setMobileError("Please enter a valid mobile number");
        return false;
      }
    } else {
      if (mobile.length < 9 || mobile.length > 11) {
        setMobileError("Please enter a valid mobile number");
        return false;
      }
    }

    setMobileError("");
    return true;
  };

  const handleEmailBlur = () => {
    validateEmail(formData.email);
  };

  const handleMobileBlur = () => {
    validateMobile(formData.mobile, formData.countryCode);
  };

  const handleDownload = () => {
    window.location.href =
      "https://drive.google.com/file/d/1Z6o02flhS8f9zMiNpbMS3ifldX8hA3tS/view?usp=sharing";
  };

  return (
    <>
      <div className="min-h-screen bg-orange-50">
        <div className="relative">
          <Popup
            showPopup={showPopup}
            onClose={handleClosePopup}
            formData={formData}
            onFormDataChange={setFormData}
            isSubmitting={isSubmitting}
            countryCodes={countryCodes}
            emailError={emailError}
            mobileError={mobileError}
            onEmailBlur={handleEmailBlur}
            onMobileBlur={handleMobileBlur}
          />
        </div>

        <div className="max-w-6xl mx-auto p-4 sm:p-6 md:p-8">
          <h1 className="flex justify-center text-3xl sm:text-4xl md:text-5xl font-bold mb-6 sm:mb-8">
            Doctor of Business Administration
          </h1>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8 lg:gap-12 mb-12">
            <div className="md:col-span-2">
              <img
                src={Posterimg}
                alt="DBA Program"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
            <div className="md:col-span-1">
              <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
                Program Overview
              </h2>
              <p className="text-gray-700 text-lg sm:text-xl mb-6">
                Our DBA program is designed for experienced professionals who
                want to advance their careers to the highest level. This program
                combines rigorous academic research with practical business
                applications.
              </p>
              <div className="space-y-4">
                <div className="flex items-center mb-8">
                  <span className="font-semibold text-xl w-32">Duration:</span>
                  <span className="text-xl">18 - 36 months</span>
                </div>
                <div className="flex justify-center">
                  <button
                    onClick={handleDownload}
                    className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 w-64 sm:w-72 md:w-80"
                  >
                    Download for more details
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
                Career Opportunities
              </h2>
              <div className="bg-white rounded-lg shadow-md p-6">
                <ul className="space-y-2 text-lg sm:text-xl text-gray-700">
                  <li>• Executive Leadership Positions</li>
                  <li>• Management Consulting</li>
                  <li>• Academic Careers</li>
                  <li>• Research and Policy Making</li>
                  <li>• Business Strategy Advisor</li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default DBAProgram;
import coorporate from '../../../../assets/coorporate/ct2.png';

const Coorporatetop = () => {
  return (
    <div className="w-full">
      {/* Image Section */}
      <div className="relative w-full">
        <img
          src={coorporate}
          alt="Corporate Training"
          className="w-full h-[700px] object-cover mt-1 "
        />
        {/* Overlay Effect */}
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-40 "></div>
      </div>

      {/* Description Section */}
      <div className="bg-white py-12 px-6 sm:px-12 text-center transform transition-all duration-300 ease-in-out">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6 sm:mb-8">
          Corporate Training Solutions
        </h2>
        <p className="text-lg sm:text-xl text-gray-700 leading-relaxed mb-4">
          Our corporate training programs are designed to enhance your team's skills and knowledge, fostering growth and productivity in your organization. Whether it’s leadership, communication, or technical training, we provide tailored solutions that meet the specific needs of your business.
        </p>
        <p className="text-lg sm:text-xl text-gray-700 leading-relaxed mb-6">
          Investing in your team's development today ensures that your workforce remains agile, innovative, and equipped to tackle tomorrow's challenges. Unlock the potential of your employees and see measurable results in your company's success!
        </p>

        <div className="text-center">
          <h3 className="text-3xl font-semibold text-gray-800">Key Benefits of Corporate Training:</h3>
          <br />
          <ul className="list-disc list-inside text-gray-700 text-left mx-auto w-full max-w-xl">
            <li className="px-5 sm:px-10">Improved employee performance and productivity</li>
            <li className="px-5 sm:px-10">Enhanced leadership and managerial skills</li>
            <li className="px-5 sm:px-10">Boosted team collaboration and communication</li>
            <li className="px-5 sm:px-10">Better customer service and client relations</li>
            <li className="px-5 sm:px-10">Increased employee satisfaction and retention</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Coorporatetop;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { fireDB } from "../../../Backend/Firebase/FirebaseConfigData"; // Your Firebase config


interface Subheading {
  id: string;
  title: string;
  subindex: number; // Include the subindex field
}

const Suggest = () => {
  const [subheadings, setSubheadings] = useState<Subheading[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Get the parent slug from the URL
  const urlPath = window.location.pathname;
  const parentSlug = urlPath.split('/').pop(); // Get the last part of the path

  useEffect(() => {
    const fetchSubheadings = async () => {
      if (!parentSlug) {
        setError("Invalid or missing slug");
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const subheadingCollection = collection(fireDB, `blogPost/${parentSlug}/subheadings`);
        const subheadingSnapShot = await getDocs(subheadingCollection);

        if (subheadingSnapShot.empty) {
          setError("No subheadings found.");
        } else {
          const fetchedSubheadings = subheadingSnapShot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
            subindex: doc.data().subindex || 0, // Ensure subindex is present
          })) as Subheading[];

          // Sort the subheadings by subindex
          const sortedSubheadings = fetchedSubheadings.sort((a, b) => a.subindex - b.subindex);
          setSubheadings(sortedSubheadings);
        }
      } catch (err) {
        setError("Failed to load subheadings.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSubheadings();
  }, [parentSlug]);

  if (loading) {
    return (
      <div className="text-center p-5">
        <div className="loader"></div> {/* Your custom loader */}
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="relative border border-gray-300 p-4 rounded-lg shadow-lg bg-white">
      <h1 className="text-2xl sm:text-3xl font-bold text-darkBlue-800 mb-6 text-center">
        Tutorials
      </h1>

      {/* List of Subheadings */}
      <ol className="list-none p-0 space-y-3">
        {subheadings.length === 0 ? (
          <li className="text-gray-500 text-center">No subheadings available.</li>
        ) : (
          subheadings.map((subheading, index) => (
            <div key={subheading.id}>
              <li
                className="cursor-pointer flex items-center py-3 px-4 rounded-lg hover:underline hover:bg-gray-200 transition-all duration-200 ease-in-out"
                onClick={() => navigate(`/bloginfo/${parentSlug}/${subheading.id}`)} // Navigate to the child subheading
              >
                <p className="text-lg font-medium text-gray-700">
                  {subheading.title} {/* Display index with title */}
                </p>
              </li>

              {/* Horizontal Line */}
              {index < subheadings.length - 1 && (
                <div className="border-t border-gray-300 mt-2"></div>
              )}
            </div>
          ))
        )}
      </ol>

      {/* Error Message */}
      {error && <div className="text-red-500 mt-4 text-center">{error}</div>}
    </div>
  );
};

export default Suggest;

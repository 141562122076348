"use client";
import { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

// import ProfileImage from "../../../assets/ProfileImage.png"; 


import { WhyUs } from './WhyUs/WhyUs';
import TeamsData from './Teams (Data and Card)/Teams_Data';
import VisionSection from './VissionSection/Vission';
import MissionSection from './MissionSection/OurMission';
import Mentors from './Mentor/mentors';



// Font configurations


export default function AboutUs() {
  
  useEffect(() => {
    // Initialize AOS (Animate on Scroll) library
    AOS.init({
      duration: 650,
      once: true,
    });

    // Handle scroll animations
    const handleScroll = () => {
      const animatedText = document.querySelectorAll('.animated-text');
      animatedText.forEach(text => {
        const textTop = text.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (textTop < windowHeight * 0.8) {
          text.classList.add('fade-in-up');
        } else {
          text.classList.remove('fade-in-up');
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
  
      <div data-aos="fade-up" className="container mx-auto px-4 md:px-8 py-6 md:py-10 sm:pt-24 mt-10">
        
        {/* Introduction Section */}
        <section className="bg-orange-300 rounded-xl shadow-xl p-6 md:p-12 sm:pt-0 md:pt-0">
  <div className="flex flex-col justify-center items-center text-center md:pt-4">
    <p className="text-3xl md:text-4xl font-bold leading-snug pt-6">
      Dear Students,
    </p>
    <p className="mt-4 text-base md:text-lg leading-relaxed max-w-3xl">
      It is with great pleasure that I welcome you to ForumDE—your gateway to opportunities in Information Technology. As technology evolves rapidly, the demand for skilled IT professionals is higher than ever. We provide industry-level IT training in the latest technologies like Cloud (Azure, AWS, GCP), Data Engineering, Data Science, Docker and Kubernetes, Data Visualization, Business Analysis, and Project Management. At ForumDE, we understand the importance of staying ahead, adapting, and honing skills to meet the industry’s dynamic needs.
    </p>
    <p className="mt-4 text-base md:text-lg leading-relaxed max-w-3xl">
      As you begin your journey toward a fulfilling career in IT, I encourage you to leverage the resources and opportunities at ForumDE. Whether you’re a professional aiming to advance your career or a fresh graduate eager to make your mark, our platform offers abundant resources, networking opportunities, and expert guidance to support you every step of the way. On behalf of the entire ForumDE team, I wish you the best in your pursuit of a rewarding career in Information Technology. Together, let’s shape the future of technology and create a brighter tomorrow for generations to come.
    </p>
    <p className="mt-6 text-base md:text-lg font-bold">
      Best Regards, <br />
      {/* <strong>Arun Kumar</strong>  */}
      {/* <br /> */}
      Founder, ForumDE
    </p>
  </div>
</section>


        {/* Why Us Section */}
        <WhyUs />

        {/* Mission Section */}
        <MissionSection />

        {/* Vision Section */}
        <VisionSection />

        {/* Mentors */}
        <Mentors />

        {/* Teams Section */}
        <section className="mt-16">
  <h1 className="text-center text-5xl md:text-6xl font-bold underline mb-8">Meet Our Team</h1>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
    {TeamsData.map((data) => (
      <div
        key={data.id}
        className="group relative flex flex-col items-center border border-gray-200 rounded-lg overflow-hidden shadow-lg transition-all duration-500 hover:scale-105 pb-6 hover:shadow-2xl"
      >
        {/* Full-Width Image with Hover Animation */}
        <img
          src={data.img}
          alt={data.name}
          className="w-fit h-64 object-cover group-hover:object-fill transition-all duration-500 transform group-hover:scale-110"
        />
        <br />
        
            <h2 className="text-3xl font-bold transition-transform duration-500 group-hover:translate-y-5">
              {data.name}
            </h2>
            <h2 className="text-2xl font-bold transition-transform duration-500 group-hover:translate-y-5">
              {data.team}
       
            </h2>
        {/* Text Overlay Positioned at the Bottom */}
        {/* <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-90 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pb-2">
          <div className="text-center text-white">
           
            <br />
            <h3 className="text-xl font-semibold transition-transform duration-500 group-hover:translate-y-10">
              {data.team}
            </h3>
          </div>
        </div> */}
      </div>
    ))}
  </div>
</section>


      </div>
    </>
  );
}

import { useState } from "react";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import logo from "../../../../assets/Header_LOGO.png";
import { fireDB } from "../../../../Backend/Firebase/FirebaseConfigData";
import InstructionsDropdown from "./GIF-Instruction";
import LottieInstructionsDropdown from "./Lottie-Instruction";

// React component to update GIF and Lottie URLs with validation dates
const UpdateGif = () => {
  const [gifUrl, setGifUrl] = useState("");
  const [lottieUrl, setLottieUrl] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // Handle the update of the GIF and Lottie URLs in Firestore
  const handleUpdateGif = async () => {
    if (!gifUrl || !lottieUrl || !fromDate || !toDate) {
      alert("Please enter valid GIF and Lottie URLs along with valid date range.");
      return;
    }

    // Convert the date strings to Date objects
    const fromDateObj = new Date(fromDate);
    const toDateObj = new Date(toDate);

    // Ensure the 'fromDate' is before 'toDate'
    if (fromDateObj >= toDateObj) {
      alert("The 'from date' must be earlier than the 'to date'.");
      return;
    }

    // Ensure 'toDate' is in the future
    const currentDate = new Date();
    if (toDateObj <= currentDate) {
      alert("The 'to date' must be in the future.");
      return;
    }

    try {
      // Create a reference to the Firestore document
      const gifRef = doc(fireDB, "OcassionGif", "current");

      // Overwrite the existing document with the new URLs and dates
      await setDoc(
        gifRef,
        {
          gifUrl: gifUrl,
          lottieUrl: lottieUrl,
          fromDate: Timestamp.fromDate(fromDateObj), // Store fromDate as a Firestore Timestamp
          toDate: Timestamp.fromDate(toDateObj), // Store toDate as a Firestore Timestamp
        },
        { merge: false }
      );

      alert("GIF and Lottie URLs updated successfully!");
    } catch (error) {
      console.error("Error updating URLs:", error);
      alert("There was an error updating the URLs. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-white to-blue-50 py-8">
      {/* Header */}
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 flex flex-col lg:flex-row justify-between items-center mb-12">
        <div className="mb-6 lg:mb-0">
          <img src={logo} alt="Logo" className="h-20 w-auto object-contain" />
        </div>
        <div className="text-center mb-6 lg:mb-0">
          <h1 className="text-5xl font-extrabold text-gray-800">ForumDE Admin</h1>
        </div>
      </div>

      <hr className="border-t-4 border-gray-300 mb-14" />

      <h1 className="text-4xl font-bold text-center text-gray-700 mb-15">Update Occasion GIF and Lottie</h1>

      {/* GIF and Lottie URL Form */}
      <div className="container mx-auto px-4 max-w-7xl my-11 mb-20 bg-white shadow-xl rounded-xl">
        <div className="space-y-6">
          {/* GIF URL Input */}
          <div>
            <label className="block text-3xl font-semibold text-gray-700 mb-2">GIF URL</label>
            <input
              type="text"
              className="w-full text-2xl border border-gray-300 rounded px-4 py-2"
              placeholder="Enter GIF URL from Cloudinary"
              value={gifUrl}
              onChange={(e) => setGifUrl(e.target.value)}
            />
          <InstructionsDropdown/>
          </div>

          {/* Lottie URL Input */}
          <div>
            <label className="block text-3xl font-semibold text-gray-700 mb-2">Lottie URL</label>
            <input
              type="text"
              className="w-full text-2xl border border-gray-300 rounded px-4 py-2"
              placeholder="Enter Lottie URL from LottieFiles"
              value={lottieUrl}
              onChange={(e) => setLottieUrl(e.target.value)}
            />
           <LottieInstructionsDropdown/>
          </div>


          {/* From and To Date Inputs */}
          <div>
            <label className="block text-3xl font-semibold text-gray-700 mb-2">From Date</label>
            <input
              type="date"
              className="w-full text-2xl border border-gray-300 rounded px-4 py-2"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>

          <div>
            <label className="block text-3xl font-semibold text-gray-700 mb-2">To Date</label>
            <input
              type="date"
              className="w-full text-2xl border border-gray-300 rounded px-4 py-2"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>

          {/* Update Button */}
          <div className="text-center ">
            <button
              className="text-xl font-bold mb-4 text-white bg-blue-500 px-4 py-2 rounded"
              onClick={handleUpdateGif}
            >
              Update GIF and Lottie URLs
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateGif;

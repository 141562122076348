import { Editor } from '@tinymce/tinymce-react';

interface BlogHeadingProps {
  title: string;
  category: string;
  index: number;
  content: string;
  setTitle: (title: string) => void;
  setCategory: (category: string) => void;
  setContent: (content: string) => void;
  handleBlogUpdate: (e: React.FormEvent) => void;
  setIndex: (index: number) => void;
}

const BlogHeading: React.FC<BlogHeadingProps> = ({
  title, category, content, setTitle, setCategory, setContent, handleBlogUpdate, index, setIndex
}) => {
  const API = import.meta.env.VITE_EDITOR;

  return (
    <div className="flex-1 bg-white p-8 rounded-lg shadow-lg max-w-4xl mx-auto">
      <form onSubmit={handleBlogUpdate}>
        {/* Title */}
        <div className="mb-6">
          <label htmlFor="title" className="block text-lg font-semibold text-gray-800 mb-2">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
            required
          />
        </div>

        {/* Category */}
        <div className="mb-6">
          <label htmlFor="category" className="block text-lg font-semibold text-gray-800 mb-2">Category</label>
          <input
            type="text"
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
            required
          />
        </div>

        {/* Content Editor */}
        <div className="mb-6">
          <label htmlFor="content" className="block text-lg font-semibold text-gray-800 mb-2">Content</label>
          <Editor
            apiKey={API}
            value={content}
            onEditorChange={(newValue) => setContent(newValue)}
            init={{
              height: 400,
              menubar: false,
              plugins: 'lists link image preview',
              toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | preview',
            }}
          />
        </div>

        {/* Index */}
        <div className="mb-6">
        <label htmlFor="index" className="block text-lg font-semibold text-gray-800 mb-2">
  Enter Index Value
</label>
<div className="flex items-center space-x-4">
  <input
    type="number"
    id="index"
    value={index}
    onChange={(e) => setIndex(Math.max(0, Number(e.target.value)))}
    className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
    required
    placeholder="Start with a positive number"
    min="0"
  />
</div>
   </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-green-600 text-white p-3 rounded-lg hover:bg-green-700 transition duration-200 ease-in-out"
        >
          Update Blog
        </button>
      </form>
    </div>
  );
};

export default BlogHeading;

"use client";

import selection from "../../../../assets/lottie/selection.json";

import Lottie from "lottie-react";
import Companies from "../../../../Additional_components/Additional/Companies/companies";
import TestimonialsCards from "./StudentCard/Testrimonials";





const Testimonials = () => {
 
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: selection,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      
    

      {/* Full-page Hero Section */}
      <section className="relative h-screen bg-black/90 text-white lg:pt-28 sm:pt-36  md:pt-32 flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between p-5 mt-auto lg:p-10">
        <div className="flex-1 flex flex-col items-start justify-center lg:py-10 lg:px-10 space-y-4 lg:space-y-5 text-left z-10 md:pt-2">
          <div className={`text-3xl sm:text-4xl md:text-5xl lg:text-5xl pt-20 font-medium  `}>
            Welcome,
          </div>
          <h2 className={`text-xl sm:text-5xl md:text-6xl lg:text-5xl font-bold  `}>
            To The Achievers Wall
          </h2>
          <span className={`text-base sm:text-lg md:text-xl lg:text-xl font-normal  `}>
            Celebrate the exceptional individuals who have made outstanding contributions to our community. Our Hall of Fame honors those whose achievements, leadership, and dedication have significantly advanced the field. Discover their stories and recognize their impact.
          </span>
        </div>
        <div className="flex-1 flex items-center justify-center lg:justify-end px-5 lg:px-5">
          <Lottie {...defaultOptions} className="w-96 h-96 p-5" />
        </div>
      </section>

      {/* Companies Section */}
      <section className="p-5 sm:p-8 lg:p-20 bg-black/90 lg:pt-10 sm:pt-32  md:pt-40">
        <div className="bg-slate-900 px-4 sm:px-6 py-6 sm:py-10 rounded-2xl border-4 border-gray-700">
          <div className="max-w-screen-lg mx-auto px-4">
            <h1 className="text-center text-3xl sm:text-4xl lg:text-4xl text-white font-extrabold mb-6 sm:mb-8">
              Where our Achievers are...
            </h1>
            <div className="flex justify-center">
              <Companies />
            </div>
          </div>
        </div>
      </section>

      {/* Testimonial cards container */}
      <section className="p-5 sm:p-8 lg:p-20 bg-black/90">
        <div className="px-4 sm:px-6 py-6 sm:py-10 rounded-2xl border-4 border-gray-700">
          <h1 className="text-center text-3xl sm:text-4xl lg:text-4xl text-white font-extrabold mb-6 sm:mb-8">
            Our Achievers...
          </h1>
          <div className="py-4 sm:py-6 px-4 sm:px-6">
            <TestimonialsCards />
          </div>
        </div>
      </section>
   
    </>
  );
};

export default Testimonials;

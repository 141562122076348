/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { fireDB } from "../../../Backend/Firebase/FirebaseConfigData";
import {
  doc,
  getDoc,
  collection,
  query,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlogHeading from "./UpdateBlog";

function UpdateBlog() {
  const { id } = useParams();

  // Blog State
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [date, setDate] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(0);

  // Subheading State
  const [, setSubheadings] = useState<any[]>([]);

  // Modal State for updating indices
  const [showModal, setShowModal] = useState(false);
  const [blogList, setBlogList] = useState<any[]>([]);
  const [newIndex, setNewIndex] = useState<number>(0); // New index for the selected blog

  // Fetch the blog data when the component is mounted
  useEffect(() => {
    const fetchBlogData = async () => {
      if (id) {
        try {
          const blogDocRef = doc(fireDB, "blogPost", id);
          const blogDoc = await getDoc(blogDocRef);
          if (blogDoc.exists()) {
            const blogData = blogDoc.data();
            setTitle(blogData?.title || "");
            setIndex(blogData?.index || 0);
            setCategory(blogData?.category || "");
            setContent(blogData?.content || "");
            setDate(blogData?.date || "");
            fetchSubheadings();
          } else {
            setError("Blog not found.");
          }
        } catch (err) {
          setError("Failed to fetch blog data.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchBlogData();
  }, [id]);

  // Fetch all subheadings for the current blog
  const fetchSubheadings = async () => {
    const subheadingRef = collection(fireDB, `blogPost/${id}/subheadings`);
    const q = query(subheadingRef);
    const querySnapshot = await getDocs(q);
    const subheadingList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setSubheadings(subheadingList);
  };

  // Handle blog update (main blog data)
  const handleBlogUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const blogCollectionRef = collection(fireDB, "blogPost");

    try {
      // Step 1: Fetch all blog posts
      const blogSnapshot = await getDocs(blogCollectionRef);
      const blogs = blogSnapshot.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title,
        index: doc.data().index,
      }));

      // Sort blogs by their index
      blogs.sort((a, b) => a.index - b.index);

      const batch = writeBatch(fireDB);

      // Step 2: Shift indices if there is a conflict (duplicate indices)
      blogs.forEach((blog) => {
        const currentIndex = blog.index;
        if (currentIndex >= index && blog.id !== id) {
          // Check for collisions and adjust
          batch.update(doc(fireDB, "blogPost", blog.id), {
            index: currentIndex + 1,
          });
        }
      });

      // Step 3: Update the current blog with the new data (title, category, content, and index)
      const blogDocRef = doc(fireDB, "blogPost", id as string);
      batch.update(blogDocRef, {
        title,
        category,
        content,
        date,
        index,
      });

      // Commit the batch update
      await batch.commit();

      toast.success("Blog updated successfully with new index!");
      window.location.reload(); // Refresh the current page after update
    } catch (err) {
      setError("Failed to update the blog.");
      toast.error("Failed to update blog");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle fetching all blogs for index updates
  const fetchBlogsForIndexUpdate = async () => {
    try {
      const blogSnapshot = await getDocs(collection(fireDB, "blogPost"));
      const blogs = blogSnapshot.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title,
        index: doc.data().index,
      }));

      setBlogList(blogs);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching blog indices:", error);
      toast.error("Failed to load blogs.");
    }
  };

  // Handle the selection of a blog from the dropdown
  const handleBlogSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedBlog = blogList.find(
      (blog) => blog.id === event.target.value
    );
    if (selectedBlog) {
      setNewIndex(selectedBlog.index);
    }
  };

  // Handle index update for the selected blog
  const handleUpdateIndex = async () => {
    try {
      const batch = writeBatch(fireDB);

      // Step 1: Update the selected blog's index to the initial index (no change from the UI)
      const selectedBlog = blogList.find((blog) => blog.id === id);
      if (selectedBlog) {
        const blogDocRef = doc(fireDB, "blogPost", selectedBlog.id);
        batch.update(blogDocRef, { index: newIndex });
      }

      // Step 2: Increment indexes of all blogs greater than or equal to the new index by 1
      const updatedBlogs = blogList.filter((blog) => blog.index >= newIndex);
      updatedBlogs.forEach((blog) => {
        const blogDocRef = doc(fireDB, "blogPost", blog.id);
        batch.update(blogDocRef, { index: blog.index + 1 });
      });

      // Commit the batch update
      await batch.commit();

      toast.success("Index updated successfully for the selected blog and other blogs!");

      // Step 3: Fetch the updated list of blogs to show the new indexes
      const updatedBlogSnapshot = await getDocs(collection(fireDB, "blogPost"));
      const updatedBlogsList = updatedBlogSnapshot.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title,
        index: doc.data().index,
      }));

      setBlogList(updatedBlogsList);
      setShowModal(false);
    } catch (error) {
      console.error("Error updating index:", error);
      toast.error("Failed to update index.");
    }
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
  };

  if (isLoading) return <div className="text-center">Loading...</div>;

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Update Blog</h1>
        {error && <div className="text-center text-red-500 mb-4">{error}</div>}

        <div className="flex flex-col lg:flex-row space-x-6">
          {/* Blog Update Form */}
          <BlogHeading
            title={title}
            category={category}
            content={content}
            index={index}
            setTitle={setTitle}
            setIndex={setIndex}
            setCategory={setCategory}
            setContent={setContent}
            handleBlogUpdate={handleBlogUpdate}
          />
        </div>

        {/* Button to open Modal for updating blog indices */}
        <div className="mt-4 text-center">
          <button
            onClick={fetchBlogsForIndexUpdate}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
          >
            Update Blog Indices
          </button>
        </div>

        {/* Modal for updating the index of a single blog */}
        {showModal && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-10">
            <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
              <h2 className="text-xl font-bold">Update all the Index</h2>
            
              <div className="space-y-4">
                <label htmlFor="blog-select" className="block text-sm">
                Select the index which you have updated to update all greater indices
                </label>
                <select
                  id="blog-select"
                  onChange={handleBlogSelect}
                  className="w-full p-2 border border-gray-300 rounded"
                >
                  <option value="">Select a blog</option>
                  {blogList.map((blog) => (
                    <option key={blog.id} value={blog.id}>
                      {blog.title} (Index: {blog.index})
                    </option>
                  ))}
                </select>

                {/* Display newIndex as read-only */}
                <div>
                  <label htmlFor="index-input" className="block text-sm">
                    New Index
                  </label>
                  <input
                    type="text"
                    id="index-input"
                    value={newIndex}
                    readOnly
                    className="w-full p-2 border border-gray-300 rounded bg-gray-100 cursor-not-allowed"
                  />
                </div>
              </div>

              <div className="mt-4 space-x-4">
                <button
                  onClick={handleUpdateIndex}
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
                >
                  Update Index
                </button>
                <button
                  onClick={closeModal}
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        <ToastContainer />
      </div>
    </div>
  );
}

export default UpdateBlog;

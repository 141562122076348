import { useState } from "react";
import logo from "../../../../assets/Header_LOGO.png";
import { fireDB } from "../../../../Backend/Firebase/FirebaseConfigData"; // Import Firestore config
import { doc, setDoc, serverTimestamp } from "firebase/firestore"; // Firestore methods
import { getAuth } from "firebase/auth"; // Firebase Authentication

const Updatetestinomials = () => {
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    description: "",
    rating: "",
    imageUrl: "", // This will store the image URL
  });

  // Handle form data changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Add new testimonial with custom document ID
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { name, companyName, description, rating, imageUrl } = formData;

    if (!name || !companyName || !description || !rating || !imageUrl) {
      alert("Please fill in all fields and provide an image URL.");
      return;
    }

    try {
      // Ensure the user is authenticated before uploading
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        alert("You need to be logged in to submit a testimonial.");
        return;
      }

      // Reference to a specific document in the testimonials collection
      const postRef = doc(fireDB, "testimonials", `T-${name}`); // Custom document ID using "T-{name}"

      // Set the document data in Firestore
      await setDoc(postRef, {
        name,
        companyName,
        description,
        rating: Number(rating), // Ensure rating is a number
        createdAt: serverTimestamp(),
        imageUrl, // Store the image URL in Firestore
      });

      alert("Testimonial added successfully!");

      // Reset form data after submission
      setFormData({
        name: "",
        companyName: "",
        description: "",
        rating: "",
        imageUrl: "", // Reset image URL
      });
    } catch (error) {
      console.error("Error adding testimonial: ", error);
      if (error instanceof Error) {
        alert(error.message);
      } else {
        console.log("An unknown error occurred.", error);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-white to-blue-50 py-8">
      {/* Header */}
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 flex flex-col lg:flex-row justify-between items-center mb-12">
        <div className="mb-6 lg:mb-0">
          <img src={logo} alt="Logo" className="h-20 w-auto object-contain" />
        </div>
        <div className="text-center mb-6 lg:mb-0">
          <h1 className="text-5xl font-extrabold text-gray-800">ForumDE Admin</h1>
        </div>
      </div>

      <hr className="border-t-4 border-gray-300 mb-14" />

      <h1 className="text-5xl font-bold text-center text-gray-700 mb-15">
        Update Testimonials
      </h1>

      {/* Form Section */}
      <div className="max-w-4xl mt-7 mx-auto px-6 sm:px-8 lg:px-12">
        <form
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          onSubmit={handleSubmit}
        >
          {/* Name Field */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter name"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* Company Name */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">
              Company Name
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              placeholder="Enter company name"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* Description */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter description"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32 resize-none"
            />
          </div>

          {/* Rating Field */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rating">
              Rating (1 to 5)
            </label>
            <input
              type="number"
              id="rating"
              name="rating"
              min="1"
              max="5"
              value={formData.rating}
              onChange={handleInputChange}
              placeholder="Enter rating (1 to 5)"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* Image URL Field */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="imageUrl">
              Image URL
            </label>
            <input
              type="text"
              id="imageUrl"
              name="imageUrl"
              value={formData.imageUrl}
              onChange={handleInputChange}
              placeholder="Enter image URL"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* Add Testimonial Button */}
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Add Testimonial
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Updatetestinomials;

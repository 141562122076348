import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { collection, getDocs } from 'firebase/firestore';
import { fireDB } from '../../../Backend/Firebase/FirebaseConfigData';
import LoadingSpinner from '../../../Additional_components/Loading/Circle Loader/loading';

interface BlogCard {
  id : string
  title: string
  date: string
  content: string
}
function BlogPostCard() {
  
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState<BlogCard[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogCollection = collection(fireDB, 'blogPost');
        const blogSnapshot = await getDocs(blogCollection);
        const blogList = blogSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as BlogCard[]; 
        setBlogs(blogList);
      } catch (err) {
        setError("Failed to fetch blogs. Please try again later.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handleBlogClick = (id:string) => {
    navigate(`/bloginfo/${id}`);
  };

  if (loading) {
    return <LoadingSpinner/>;
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">{error}</div>;
  }

  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-10 mx-auto max-w-7xl">
          <h1 className="text-3xl font-bold mb-5 text-center">All Blogs</h1>

          {/* Main Content */}
          <div className="flex flex-wrap justify-center -m-4 mb-5">
            {blogs.length > 0 ? (
              blogs.map((item) => {
                 
                const {  id, date, title} = item;

                return (
                  <div className="p-4 md:w-1/3" key={id}>
                    <div
                  
                      className={`h-full shadow-lg hover:-translate-y-1 cursor-pointer hover:shadow-gray-400  rounded-xl overflow-hidden`}
                      onClick={() => handleBlogClick(id)}
                    >
             
                      {/* Top Items */}
                      <div className="p-6">
                        {/* Blog Date */}
                        <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1" >
                          {date}
                        </h2>

                        {/* Blog Title */}
                        <h1 className="title-font text-lg font-bold mb-3">
                          {title}
                        </h1>

                        {/* Blog Description */}
                        <p className="leading-relaxed mb-3" >
                          
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h1 className='text-xl font-bold text-center'>No Blogs Found</h1>
            )}
          </div>

          {/* See More Button */}
          <div className="flex justify-center my-5">
            
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlogPostCard;

"use client";

import { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import arun from '../../assets/Mentor_image/arun.jpg'; 
import akshay from '../../assets/Mentor_image/akshay.jpeg';
// import dinesh from '../../assets/Mentor_image/dinesh.jpg';
import Azure from '../../assets/Upcoming_Course_Logo/azure.jpg';
// import python from '../../assets/Upcoming_Course_Logo/python.jpg';
import SQL from '../../assets/Upcoming_Course_Logo/sql-server.jpg';
import { doc, getDoc } from 'firebase/firestore';
import { fireDB } from '../../Backend/Firebase/FirebaseConfigData';

// Function to calculate days left or show "Upcoming" if the date has passed
const calculateDaysLeft = (date: string | number | Date) => {
    const eventDate = new Date(date);
    const today = new Date();
    const timeDiff = eventDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    // Return "Upcoming" if the event date has passed
    return daysLeft > 0 ? `${daysLeft} Days To Go` : "Already Started";
};

const Upcoming = () => {
    const [upcomingDetails, setUpcomingDetails] = useState([
        {
            id: 1,
            name: "Azure",
            date: "Upcoming",  // Default placeholder
            time: "10:00 AM",
            tutor: arun,
            course: Azure,
            mentor: "Arun Kumar"
        },
        // {
        //     id: 2,
        //     name: "Python",
        //     date: "Upcoming",  // Default placeholder
        //     time: "10:00 AM",
        //     tutor: dinesh,
        //     course: python,
        //     mentor: "Dinesh Kumar"
        // },
        {
            id: 3,
            name: "SQL",
            date: "Upcoming",  // Default placeholder
            time: "10:00 AM",
            tutor: akshay,
            course: SQL,
            mentor: "Akshay Kaushal"
        },
    ]);

    useEffect(() => {
        AOS.init();

        const fetchCourseDates = async () => {
            const updatedDetails = [...upcomingDetails];
            for (let i = 0; i < updatedDetails.length; i++) {
                const courseName = updatedDetails[i].name;
                const courseDocRef = doc(fireDB, 'batchdetails', courseName);
                try {
                    const docSnapshot = await getDoc(courseDocRef);
                    console.log(`Fetching data for ${courseName}`);

                    if (docSnapshot.exists()) {
                        const courseData = docSnapshot.data();
                        console.log(`Fetched data: `, courseData);

                        // Update the date from Firestore, or set "No Date Set" if no date exists
                        const courseDate = courseData?.date
                            ? courseData.date.toDate().toISOString().split('T')[0]
                            : "No Date Set";

                        // Dynamically update the course name with the id
                        updatedDetails[i].name = courseName; // Use courseName here dynamically
                        updatedDetails[i].date = courseDate;  // Set the fetched date
                    } else {
                        console.log(`${courseName} document does not exist`);
                        updatedDetails[i].name = courseName;  // Keep course name
                        updatedDetails[i].date = "No Date Set";  // Set fallback value
                    }
                } catch (error) {
                    console.error(`Error fetching data for ${courseName}:`, error);
                    updatedDetails[i].name = courseName;  // Keep course name
                    updatedDetails[i].date = "Error Fetching Date";  // Handle errors
                }
            }
            setUpcomingDetails(updatedDetails);  // Update state with the fetched details
        };

        fetchCourseDates();
    }, []);  // Run this effect once when the component mounts

    return (
        <div className="w-full max-w-5xl mx-auto px-2">
            <h1 className='text-2xl sm:text-4xl md:text-3xl lg:text-5xl text-center font-bold p-4 sm:p-6 underline' data-aos="fade-up" data-aos-delay="800">
                Upcoming Events
            </h1>
            {upcomingDetails.map((data) => (
                <div key={data.id} className='flex flex-col md:flex-row items-center justify-center text-center w-full my-4' data-aos="fade-up" data-aos-delay="500">
                    <div className='p-4 w-full'>
                        <div className='rounded-xl p-4 md:p-6 w-full flex flex-col md:flex-row items-center justify-between border-2 border-black shadow-xl'>
                            {/* Course Image and Name */}
                            <div className='px-4 md:px-6 flex flex-col items-center md:items-start'>
                                <img
                                    src={data.course}
                                    alt={`${data.name} Course`}
                                    className='w-20 sm:w-24 md:w-32 p-2 sm:p-4'
                                    width={128}
                                    height={128}
                                />
                                <h2 className='text-lg sm:text-xl md:text-2xl mt-3'>{data.name}</h2> {/* Dynamically display course name */}
                            </div>

                            {/* Event Date */}
                            <div className='px-4 md:px-6 flex flex-col items-center'>
                                <h1 className='text-base sm:text-lg md:text-xl font-semibold'>New Batch From</h1>
                                <h2 className='text-xl sm:text-2xl md:text-4xl'>{data.date === "No Date Set" || data.date === "Error Fetching Date" ? "Upcoming" : data.date}</h2> {/* If no date set or error, display "Upcoming" */}
                            </div>

                            {/* Days Left */}
                            <div className=' p-4 md:w-auto my-4 sm:p-4 rounded-xl border-2  border-collapse border-green-900 flex-col items-center'>
                                <p className='text-xl sm:text-2xl md:text-3xl text-red-500 font-bold'>
                                    {calculateDaysLeft(data.date)} {/* Display days left or "Upcoming" */}
                                </p>
                  
                            </div>

                            {/* Tutor Image and Mentor Name */}
                            <div className='px-4 md:px-6 flex flex-col items-center md:items-end'>
                                <img
                                    src={data.tutor}
                                    alt={`${data.name} Tutor`}
                                    className='rounded-full w-20 sm:w-24 md:w-36 h-20 sm:h-24 md:h-36 object-cover'
                                    width={144}
                                    height={144}
                                />
                                <div className='flex flex-col sm:flex-row items-center mt-3'>
                                    <h1 className='text-base sm:text-lg md:text-xl font-semibold'>By,</h1>
                                    <h2 className='text-base sm:text-lg md:text-xl font-semibold ml-2'>{data.mentor}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Upcoming;

import BlogPostCard from './blogPostCard/Blogpostcard';
import BlogNavbar from './navbar/Navbar';

const MainBlogpage = () => {
  return (
    <>
    <BlogNavbar/>
    <BlogPostCard/>
  
    </>
  );
}

export default MainBlogpage;

import HD from "../../assets/Ad_Poster/hd.jpeg";

import  { useState } from "react";
import HDPopupForm from "./HDForm";

interface FormData {
  fullName: string;
  email: string;
  countryCode: string;
  mobile: string;
  agreed: boolean;
}

const HDProgram = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [isSubmitting] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    countryCode: "+91",
    mobile: "",
    agreed: false,
  });

  const countryCodes = [
    { code: "+91", country: "IN" },
    { code: "+1", country: "US" },
    { code: "+44", country: "UK" },
    { code: "+61", country: "AU" },
    { code: "+86", country: "CN" },
    { code: "+81", country: "JP" },
    { code: "+49", country: "DE" },
    { code: "+33", country: "FR" },
  ];

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleDownload = () => {
    // Google Drive URL
    window.location.href =
      "https://drive.google.com/file/d/1jqWdSVnJ4qmIuVvpc62j6Hviv-ZmbffM/view?usp=sharing";
  };

  return (
    <div className="min-h-screen bg-orange-50">
      <div className="relative">
        <HDPopupForm
          showPopup={showPopup}
          onClose={handleClosePopup}
          formData={formData}
          onFormDataChange={setFormData}
          isSubmitting={isSubmitting}
          countryCodes={countryCodes}
        />
      </div>

      {/* Main Content starts */}
      <div className="max-w-6xl mx-auto p-8">
        <h1 className="text-6xl font-bold text-center mb-8">Honorary Doctorate</h1>

        <div className="grid md:grid-cols-3 gap-12 mb-12">
          <div className="md:col-span-2">
            <img
              src={HD}
              alt="DBA Program"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="md:col-span-1">
            <h2 className="text-3xl font-semibold mb-4">Program Overview</h2>
            <p className="text-gray-700 p-2 text-xl mb-1">
              At the International Business School of Scandinavia, awarding an honorary doctorate is a special occasion. This degree is given to individuals who have achieved greatly in their fields and in making society better, reflecting our school's values.
            </p>
            <p className="text-gray-700 p-2 text-xl mb-4">
              When selecting recipients for this honor, the International Business School of Scandinavia follows the principle that by honoring individuals for their contributions, the school also honors itself through its association with people of outstanding merit.
            </p>
            <div className="space-y-4">
              {/* Download Button */}
              <div className="flex justify-center">
                <button
                  onClick={handleDownload}
                  className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 w-64"
                >
                  Download for more details
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Mission Section */}
        <div className="bg-white py-12 px-6 sm:px-12 text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6 sm:mb-8">Our Vision and Mission</h2>
          <p className="text-lg sm:text-xl text-gray-700 leading-relaxed mb-4">
            We aspire to give our students access to high quality, internationally accredited, flexible education at a good price. We aim to deliver teaching and learning opportunities of the highest quality.
          </p>
          <p className="text-lg sm:text-xl text-gray-700 leading-relaxed mb-6">
            IBSS is dedicated to delivering high-quality knowledge as well as state of the art skills, enabling today’s students to engage in business management issues with pertinence and self-confidence.
          </p>
        </div>

        {/* Values Section */}
        <div className="space-y-8 mt-11">
          <section>
            <h2 className="ml-3 text-3xl font-semibold mb-4">Our Values</h2>
            <div className="bg-white rounded-lg shadow-md p-6">
              <ul className="space-y-2 text-xl text-gray-700">
                <li>• To bring high quality blended learning in the coming decade.</li>
                <li>• To bring International quality business education to people in the globally emerging economies.</li>
                <li>• To offer International quality business education at an affordable price.</li>
                <li>• To improve applied management and business development skills.</li>
                <li>• To become a global education provider in the field of Business, and Management.</li>
              </ul>
            </div>
          </section>
        </div>

        {/* Committee Section */}
        <div className="bg-gray-100 py-12 px-6 sm:px-12 text-start">
          <h2 className="text-3xl md:text-4xl text-center font-bold text-gray-800 mb-6 sm:mb-8">Honorary Degrees Committee</h2>
          <p className="text-lg sm:text-xl text-gray-700 leading-relaxed mb-4">
            The Honorary Degrees Committee at IBSS is appointed each year by the Senate and includes the Registrar along with other members as designated by the Senate. The committee's primary responsibility is to identify and select candidates to recommend to the Senate for the conferment of honorary degrees. This process ensures that recipients reflect the school's commitment to excellence and societal contribution.
          </p>
        </div>

        {/* Admission Section */}
        <div className="bg-white py-12 px-6 sm:px-12 text-start">
          <h2 className="text-3xl text-center md:text-4xl font-bold text-gray-800 mb-6 sm:mb-8">Admission Procedure</h2>
          <p className="text-lg sm:text-xl text-gray-700 leading-relaxed mb-4">
            Candidates can apply for admission through our online admission system or by obtaining an application form from ForumDE. Eligible candidates should submit the completed application form along with the required documents to the school's official email address at any time.
          </p>
          <p className="text-lg sm:text-xl text-gray-700 leading-relaxed mb-4">
            Once the Registrar's office receives the completed application form, it undergoes necessary verification according to the admission eligibility criteria. The candidate will be notified primarily via email (if an email address is provided in the application form) once the application is processed.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HDProgram;

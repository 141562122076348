/* eslint-disable @typescript-eslint/no-unused-vars */
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { fireDB } from "../../../../Backend/Firebase/FirebaseConfigData";
import { doc, collection, query, getDocs, writeBatch, deleteDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "@tinymce/tinymce-react";

// Subheading interface with correct typing for subindex
interface Subheading {
  id: string;
  title: string;
  content: string;
  slug: string;
  subindex: number;
}

const API = import.meta.env.VITE_EDITOR;

function SubheadingUpdate() {
  const { id } = useParams();

  // Subheading State
  const [subheadings, setSubheadings] = useState<Subheading[]>([]);
  const [subheadingData, setSubheadingData] = useState<Subheading>({
    id: '',
    title: '',
    content: '',
    slug: '',
    subindex: 0
  });
  const [selectedSubheading, setSelectedSubheading] = useState<string | null>(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [newIndex, setNewIndex] = useState<number>(0);

  // Fetch subheadings for the current blog post
  useEffect(() => {
    const fetchSubheadings = async () => {
      if (id) {
        try {
          const subheadingRef = collection(fireDB, `blogPost/${id}/subheadings`);
          const q = query(subheadingRef);
          const querySnapshot = await getDocs(q);
          
          const subheadingList: Subheading[] = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              title: data.title || "",
              content: data.content || "",
              slug: data.slug || "",
              subindex: data.subindex !== undefined ? data.subindex : 0, // Ensure subindex is defined
            };
          });
          setSubheadings(subheadingList);
        } catch (error) {
          setError("Failed to fetch subheadings.");
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchSubheadings();
  }, [id]);

  // Handle subheading selection
  const handleSubheadingSelect = (slug: string) => {
    const subheading = subheadings.find(sub => sub.slug === slug);
    if (subheading) {
      setSubheadingData({
        title: subheading.title,
        content: subheading.content,
        slug: subheading.id,
        subindex: subheading.subindex,
        id: subheading.id,
      });
      setSelectedSubheading(slug);
    }
  };

  // Handle subheading update
  const handleSubheadingUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const subheadingCollectionRef = collection(fireDB, `blogPost/${id}/subheadings`);

    try {
      // Step 1: Fetch all subheadings for index management
      const subheadingSnapshot = await getDocs(subheadingCollectionRef);
      const subheadingList = subheadingSnapshot.docs.map((doc) => ({
        id: doc.id,
        subindex: doc.data().subindex,
        title: doc.data().title,
        content: doc.data().content,
      })) as Subheading[];

      // Sort subheadings by their subindex
      subheadingList.sort((a, b) => a.subindex - b.subindex);

      const batch = writeBatch(fireDB);

      // Step 2: Shift indices if there is a conflict (duplicate subindices)
      subheadingList.forEach((subheading) => {
        const currentSubindex = subheading.subindex;
        if (currentSubindex >= subheadingData.subindex && subheading.id !== subheadingData.slug) {
          // Check for collisions and adjust
          batch.update(doc(fireDB, `blogPost/${id}/subheadings/${subheading.id}`), {
            subindex: currentSubindex + 1,
          });
        }
      });

      // Step 3: Update the selected subheading with the new data
      const subheadingDocRef = doc(fireDB, `blogPost/${id}/subheadings/${subheadingData.slug}`);
      batch.update(subheadingDocRef, {
        title: subheadingData.title,
        content: subheadingData.content,
        subindex: subheadingData.subindex,
      });

      // Commit the batch update
      await batch.commit();

      toast.success("Subheading updated successfully with new index!");
      setSelectedSubheading(null);
      setSubheadingData({ id: '', title: '', content: '', slug: '', subindex: 0 });
      setShowModal(false);

      // Optionally refresh the list or redirect after the update
      window.location.reload(); // Refresh the page to get the updated list
    } catch (err) {
      setError("Failed to update the subheading.");
      toast.error("Failed to update subheading");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle subheading delete
  const handleSubheadingDelete = async () => {
    if (!selectedSubheading) return;

    const confirmed = window.confirm("Are you sure you want to delete this subheading?");
    if (confirmed) {
      try {
        const subheadingRef = doc(fireDB, `blogPost/${id}/subheadings/${selectedSubheading}`);
        await deleteDoc(subheadingRef);
        toast.success("Subheading deleted successfully!");
        
        // Remove the deleted subheading from the state
        setSubheadings(subheadings.filter(subheading => subheading.id !== selectedSubheading));
        setSelectedSubheading(null);
        setSubheadingData({ id: '', title: '', content: '', slug: '', subindex: 0 });
      } catch (error) {
        toast.error("Failed to delete subheading.");
      }
    }
  };

  // Trigger the modal opening to log indices
  const openModal = () => {
    setShowModal(true);

    // Log all subheading indices when the modal is opened
    subheadings.forEach(subheading => {
      console.log(`Subheading: ${subheading.title}, Index: ${subheading.subindex}`);
    });
  };

  if (isLoading) return <div className="text-center">Loading...</div>;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="w-full max-w-4xl p-8 bg-white rounded-xl shadow-xl sm:max-w-xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl">
        <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">Update Subheading</h1>
        {error && <div className="text-center text-red-500 mb-4">{error}</div>}

        {/* Update Subheading Form */}
        <form onSubmit={handleSubheadingUpdate}>
          <div className="mb-6">
            <label htmlFor="select-subheading" className="block text-lg text-gray-700">Select Subheading to Update</label>
            <select
              id="select-subheading"
              onChange={(e) => handleSubheadingSelect(e.target.value)}
              className="w-full p-4 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
            >
              <option value="">--Select Subheading--</option>
              {subheadings.map((subheading) => (
                <option key={subheading.id} value={subheading.id}>{subheading.title}</option>
              ))}
            </select>
          </div>

          {selectedSubheading && (
            <>
              <div className="mb-6">
                <label htmlFor="subheading-update-title" className="block text-lg text-gray-700">Title</label>
                <input
                  type="text"
                  id="subheading-update-title"
                  value={subheadingData.title}
                  onChange={(e) => setSubheadingData({ ...subheadingData, title: e.target.value })}
                  className="w-full p-4 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>
              <div className="mb-6">
                <label htmlFor="subheading-update-index" className="block text-lg text-gray-700">Index</label>
                <input
                  type="number"
                  id="subheading-update-index"
                  value={subheadingData.subindex}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (value > 0 || e.target.value === "") {
                      setSubheadingData({ ...subheadingData, subindex: value });
                    }
                  }}
                  min="1"
                  className="w-full p-4 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>

              <div className="mb-8">
                <label htmlFor="content" className="block text-lg font-semibold text-gray-700 mb-4">Content</label>
                <Editor
                  apiKey={API}
                  value={subheadingData.content}
                  onEditorChange={(newValue) => setSubheadingData({ ...subheadingData, content: newValue })}
                  init={{
                    height: 400,
                    menubar: false,
                    plugins: 'lists link image preview',
                    toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | preview',
                  }}
                />
              </div>

              <button
                type="submit"
                className="w-full bg-green-600 text-white py-4 rounded-md hover:bg-green-700 transition"
              >
                Update Subheading
              </button>

              {/* Button to delete the subheading */}
              <button
                type="button"
                onClick={handleSubheadingDelete}
                className="w-full bg-red-600 text-white py-4 mt-4 rounded-md hover:bg-red-700 transition"
              >
                Delete Subheading
              </button>
            </>
          )}
        </form>

        {/* Button to open modal for index update */}
        <div className="mt-6 text-center">
          <button
            onClick={openModal}
            className="bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition"
          >
            Update Subheading Index
          </button>
        </div>
      </div>

      {/* Modal for updating subheading index */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full">
            <h3 className="text-2xl font-semibold text-center mb-6">Update all the Index</h3>

            <div className="mb-6">
              <label htmlFor="new-index" className="block text-lg text-gray-700">Select the index which you have updated to update all greater indices</label>
              <select
                id="new-index"
                value={newIndex}
                onChange={(e) => setNewIndex(parseInt(e.target.value, 10))}
                className="w-full p-4 border border-gray-300 rounded-md"
              >
                <option value="">--Select New Index--</option>
                {subheadings.map(subheading => (
                  <option key={subheading.id} value={subheading.subindex}>
                    {subheading.title} - Index {subheading.subindex}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex justify-between">
              <button
                onClick={handleSubheadingUpdate}
                className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
              >
                Update Index+1
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-red-600 text-white px-6 py-2 rounded-md hover:bg-red-700"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubheadingUpdate;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
// Get the Firebase API key from environment variables
const APP_FIREBASE = import.meta.env.VITE_FIREBASE; // Ensure this variable is set correctly in your .env file

const firebaseConfig = {
  apiKey: APP_FIREBASE, // Use the environment variable for apiKey
  authDomain: "forumde-16307.firebaseapp.com",
  projectId: "forumde-16307",
  storageBucket: "forumde-16307.firebasestorage.app",
  messagingSenderId: "839676330554",
  appId: "1:839676330554:web:4bf892d92169d2b8e36b03",
  measurementId: "G-G8Y2NH1E4Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fireDB = getFirestore(app);
const rtdb = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { fireDB, auth, storage, rtdb, app };

  
import { useState } from "react";
import { rtdb } from "../../../../Backend/Firebase/FirebaseConfigData"; // Import Realtime DB
import { ref, push, set } from "firebase/database";
import Hiretop from "./hireuscontent";

const HireForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    countryCode: "+91",
    mobile: "",
    agreed: false,
  });

  const [emailError, setEmailError] = useState<string>("");
  const [mobileError, setMobileError] = useState<string>("");

  // Country Codes
  const countryCodes = [
    { code: "+91", country: "IN" },
    { code: "+1", country: "US" },
    { code: "+44", country: "UK" },
    { code: "+61", country: "AU" },
    { code: "+86", country: "CN" },
    { code: "+81", country: "JP" },
    { code: "+49", country: "DE" },
    { code: "+33", country: "FR" },
  ];

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email) || !email.endsWith(".com")) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validateMobile = (mobile: string, countryCode: string) => {
    // Ensure only numbers are allowed for all countries
    const numericRegex = /^\d+$/;
    if (!numericRegex.test(mobile)) {
      setMobileError("Only numeric characters are allowed");
      return false;
    }

    // Specific validation for India
    if (countryCode === "+91") {
      if (mobile.length !== 10) {
        setMobileError("Please enter a valid mobile number");
        return false;
      }
    } else {
      // Validation for other countries
      if (mobile.length < 9 || mobile.length > 11) {
        setMobileError("Please enter a valid mobile number");
        return false;
      }
    }

    setMobileError("");
    return true;
  };

  const handleEmailBlur = () => {
    validateEmail(formData.email);
  };

  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow only numeric input
    const numericValue = inputValue.replace(/\D/g, "");

    // Update the mobile field with the numeric value
    setFormData({ ...formData, mobile: numericValue });

    // Validate the mobile number length based on the country code
    validateMobile(numericValue, formData.countryCode);
  };

  const handleMobileBlur = () => {
    validateMobile(formData.mobile, formData.countryCode);
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!formData.agreed) {
      alert("Please accept the terms and privacy policy");
      return;
    }

    const isEmailValid = validateEmail(formData.email);
    const isMobileValid = validateMobile(formData.mobile, formData.countryCode);

    if (!isEmailValid || !isMobileValid) {
      return; // Stop submission if validation fails
    }

    try {
      // Push form data to Realtime Database
      const newFormRef = push(ref(rtdb, "Hire From Us")); // Create a new entry under 'formData'
      await set(newFormRef, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        company: formData.company,
        countryCode: formData.countryCode,
        mobile: formData.mobile,
        timestamp: new Date().toISOString(), // Add timestamp (ISO string)
      });

      alert("We'll get back to you soon!!");
      // Reset form data
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        countryCode: "+91",
        mobile: "",
        agreed: false,
      });

      window.location.reload();
    } catch (error) {
      console.error("Error submitting form data:", error);
      alert("There was an error submitting your form. Please try again later.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br to-black/5 flex flex-col items-center">
      <Hiretop />

      {/* Form Section */}
      <div className="bg-orange-100 w-full max-w-3xl rounded-2xl shadow-xl p-8 border border-orange-200 mt-8">
        <h1 className="text-3xl font-bold text-center text-orange-600 mb-6">Hire From Us</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* First Name and Last Name Inputs */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="relative">
              <label htmlFor="firstName" className="text-sm text-gray-600">First Name *</label>
              <input
                type="text"
                id="firstName"
                placeholder="First Name *"
                className="w-full px-4 py-3 rounded-xl border-2 border-orange-100 focus:border-orange-400 focus:outline-none transition-colors"
                required
                value={formData.firstName}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              />
            </div>
            <div className="relative">
              <label htmlFor="lastName" className="text-sm text-gray-600">Last Name *</label>
              <input
                type="text"
                id="lastName"
                placeholder="Last Name *"
                className="w-full px-4 py-3 rounded-xl border-2 border-orange-100 focus:border-orange-400 focus:outline-none transition-colors"
                required
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              />
            </div>
          </div>

          {/* Email Input */}
          <div className="relative">
            <label htmlFor="email" className="text-sm text-gray-600">Email Address *</label>
            <input
              type="email"
              id="email"
              placeholder="Email Address *"
              className={`w-full px-4 py-3 rounded-xl border-2 ${
                emailError ? "border-red-500" : "border-orange-100"
              } focus:border-orange-400 focus:outline-none transition-colors`}
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              onBlur={handleEmailBlur} // Validate email on blur
            />
            {emailError && (
              <p className="text-red-500 text-sm mt-1">{emailError}</p>
            )}
          </div>

          {/* Company Name Input */}
          <div className="relative">
            <label htmlFor="company" className="text-sm text-gray-600">Company Name *</label>
            <input
              type="text"
              id="company"
              placeholder="Company Name *"
              className="w-full px-4 py-3 rounded-xl border-2 border-orange-100 focus:border-orange-400 focus:outline-none transition-colors"
              required
              value={formData.company}
              onChange={(e) => setFormData({ ...formData, company: e.target.value })}
            />
          </div>

          {/* Country Code and Mobile Inputs */}
          <div className="flex gap-4">
            <div className="relative w-1/3">
              <label htmlFor="countryCode" className="text-sm text-gray-600">Country Code *</label>
              <select
                id="countryCode"
                className="w-full px-3 py-3 rounded-xl border-2 border-orange-100 focus:border-orange-400 focus:outline-none transition-colors bg-white"
                value={formData.countryCode}
                onChange={(e) => setFormData({ ...formData, countryCode: e.target.value })}
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.code} ({country.country})
                  </option>
                ))}
              </select>
            </div>
            <div className="relative flex-1">
              <label htmlFor="mobile" className="text-sm text-gray-600">Mobile Number *</label>
              <input
                type="tel"
                id="mobile"
                placeholder="Mobile Number *"
                className={`w-full px-4 py-3 rounded-xl border-2 ${
                  mobileError ? "border-red-500" : "border-orange-100"
                } focus:border-orange-400 focus:outline-none transition-colors`}
                required
                value={formData.mobile}
                onChange={handleMobileChange} // Restrict non-numeric input
                onBlur={handleMobileBlur} // Validate mobile on blur
                maxLength={formData.countryCode === "+91" ? 10 : 11} // Set max length based on country code
              />
              {mobileError && (
                <p className="text-red-500 text-sm mt-1">{mobileError}</p>
              )}
            </div>
          </div>

          {/* Terms and Conditions Checkbox */}
          <div className="flex items-start gap-2">
            <input
              type="checkbox"
              id="terms"
              className="mt-1"
              checked={formData.agreed}
              onChange={(e) => setFormData({ ...formData, agreed: e.target.checked })}
              required
            />
            <label htmlFor="terms" className="text-sm text-gray-600">
              By providing your contact details, you agree to our{" "}
              <a href="/Terms" className="text-orange-600 hover:text-orange-700 underline">
                Terms of Use
              </a>{" "}
              &{" "}
              <a href="/Private" className="text-orange-600 hover:text-orange-700 underline">
                Privacy Policy
              </a>
            </label>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-orange-500 to-orange-600 text-white font-semibold py-8 rounded-xl hover:from-orange-600 hover:to-orange-700 transform hover:-translate-y-0.5 transition-all duration-200 shadow-lg hover:shadow-xl"
          >
            Get In Touch
          </button>
        </form>
      </div>
      <br /><br />
    </div>
  );
};

export default HireForm;
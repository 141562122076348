/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Editor } from '@tinymce/tinymce-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fireDB } from "../../../../Backend/Firebase/FirebaseConfigData";

// Modal component for index input
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const IndexInputModal = ({ isOpen, onClose, onSubmit }: any) => {
  const [indexValue, setIndexValue] = useState<number | string>("");

  const handleSubmit = () => {
    if (indexValue === "" || isNaN(Number(indexValue))) {
      toast.error("Please enter a valid number.");
    } else {
      onSubmit(Number(indexValue));
      onClose();
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-700 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-lg w-1/3">
        <h2 className="text-xl font-semibold text-gray-700">Enter Index Number</h2>
        <input
          type="number"
          value={indexValue}
          onChange={(e) => setIndexValue(e.target.value)}
          className="w-full p-2 mt-4 border border-gray-300 rounded"
          placeholder="Enter index number"
        />
        <div className="flex justify-between mt-4">
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

const Createnew = () => {
  const API = import.meta.env.VITE_EDITOR; // Environment variable for the editor API key
  const { id } = useParams();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [subheadingData, setSubheadingData] = useState({
    title: '',
    index: '', 
    content: '',
    slug: '',
  });

  const [isIndexModalOpen, setIsIndexModalOpen] = useState(false);

  useEffect(() => {
    const fetchBlogData = async () => {
      if (id) {
        try {
          const blogDocRef = doc(fireDB, "blogPost", id);
          const blogDoc = await getDoc(blogDocRef);
          if (!blogDoc.exists()) {
            setError("Blog not found.");
          }
        } catch (err) {
          setError("Failed to fetch blog data.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchBlogData();
  }, [id]);

  const handleNewSubheading = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!subheadingData.slug || !subheadingData.title || !subheadingData.content || !subheadingData.index) {
      toast.error("Please fill out all fields, including the slug and index.");
      return;
    }

    setIsLoading(true);
    try {
      const newSubheadingRef = doc(fireDB, `blogPost/${id}/subheadings`, subheadingData.slug);
      await setDoc(newSubheadingRef, {
        title: subheadingData.title,
        content: subheadingData.content,
        slug: subheadingData.slug,
        index: subheadingData.index,
      });

      setSubheadingData({
        title: '',
        content: '',
        slug: '',
        index: '', 
      });

      toast.success('Subheading created successfully!');
    } catch (error) {
      toast.error('Error creating subheading');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const openIndexModal = () => {
    setIsIndexModalOpen(true);
  };

  const closeIndexModal = () => {
    setIsIndexModalOpen(false);
  };

  const handleSetIndex = (index: number) => {
    setSubheadingData({
      ...subheadingData,
      index: index.toString(),
    });
  };

  if (isLoading) return <div className="text-center">Loading...</div>;

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-800 content-center mb-6">Create New Subheading</h1>
        {error && <div className="text-center text-red-500 mb-4">{error}</div>}

        <div className="flex flex-col lg:flex-row space-x-6">
          <div className="flex-1 bg-white p-6 rounded shadow-md mt-6 lg:mt-0">
            <h2 className="text-xl font-semibold text-gray-800">Create New Subheading</h2>
            <form onSubmit={handleNewSubheading}>
              <div className="mb-4">
                <label htmlFor="subheading-title" className="block text-gray-700">Subheading Title</label>
                <input
                  type="text"
                  id="subheading-title"
                  value={subheadingData.title}
                  onChange={(e) => setSubheadingData({ ...subheadingData, title: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <h1 className=" text-red-600 text-3xl font-extrabold">**Make sure Slug ID should be unique**</h1>
                <label htmlFor="subheading-slug" className="block text-gray-700">Slug (Unique Identifier)</label>
                <input
                  type="text"
                  id="subheading-slug"
                  value={subheadingData.slug}
                  onChange={(e) => setSubheadingData({ ...subheadingData, slug: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="index" className="block text-gray-700">Index</label>
                <input
                  type="text"
                  id="index"
                  value={subheadingData.index}
                  readOnly
                  onClick={openIndexModal}
                  className="w-full p-2 border border-gray-300 rounded bg-gray-200 cursor-pointer"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="subheading-content" className="block text-gray-700">Content</label>
                <Editor
                  apiKey={API}
                  value={subheadingData.content}
                  onEditorChange={(newValue) => setSubheadingData({ ...subheadingData, content: newValue })}
                  init={{
                    height: 400,
                    menubar: false,
                    plugins: 'lists link image preview',
                    toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | preview',
                  }}
                />
              </div>

              <div className="mb-4">
                <button
                  type="submit"
                  className="w-full bg-green-600 text-white p-2 rounded hover:bg-green-700"
                >
                  Create Subheading
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Index Input Modal */}
        <IndexInputModal
          isOpen={isIndexModalOpen}
          onClose={closeIndexModal}
          onSubmit={handleSetIndex}
        />

        <ToastContainer />
      </div>
    </div>
  );
};

export default Createnew;

import Slider from "react-slick";
import raj from "../../assets/student_pic/raj.jpg";
import shubham from "../../assets/student_pic/shubham.jpg";
import krishna from "../../assets/student_pic/krishna.jpg";
import axa from '../../assets/working_logo/axa.png';
import publicis from '../../assets/working_logo/publicis.png';
import circlek from '../../assets/working_logo/Circlek.png';
import diptanu from '../../assets/student_pic/diptanu.jpg';
import cg from '../../assets/working_logo/convegenius.jpg';
import ey from '../../assets/working_logo/ey.webp';
import das from "../../assets/student_pic/das.jpeg";
import alok from "../../assets/student_pic/alok.jpeg";

const googleFonts = (
  <link
    href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Noto+Serif:wght@400;700&display=swap"
    rel="stylesheet"
  />
);

const testimonialData = [
  {
    id: 1,
    name: 'Raj Singh',
    image: raj,
    companylogo: axa,
    companyname: 'AXA',
    description: "I am writing to express my deepest gratitude for your exceptional mentorship and support during my Azure Data Engineering course journey. Your guidance and expertise have been instrumental in not only helping me navigate the complexities of the course but also in securing a new job with a remarkable 50% hike.",
    aosDelay: "650",
  },
  {
    id: 2,
    name: 'Krishan Pal',
    companylogo: ey,
    companyname: 'EY',
    image: krishna,
    description: "I am incredibly grateful for the guidance and mentorship provided by Mr. Arun Kumar throughout my journey in data engineering. Under his expert tutelage, I not only acquired invaluable skills but also achieved an astounding 180% hike in my career trajectory.",
    aosDelay: "650",
  },
  {
    id: 3,
    name: 'Shubham Naikanavare',
    companylogo: publicis,
    companyname: 'Publicis Sapient',
    image: shubham,
    description: "I am immensely grateful for the exceptional mentorship and guidance provided by Mr. Arun Kumar during my journey into Azure data engineering. With his invaluable support, I not only gained a deeper understanding of Azure technologies but also secured a new job with an incredible hike.",
    aosDelay: "650",
  },
  {
    id: 4,
    name: 'Diptanu Debnath',
    companylogo: circlek,
    companyname: 'CircleK',
    image: diptanu,
    description: "Joining ForumDE has been a transformative experience for me. With their exceptional data engineering solutions and support, I was able to achieve remarkable career growth. In just one year, I received a 200% salary hike at Circle K, thanks to the enhanced skills and insights I gained.",
    aosDelay: "650",
  },
  {
    id: 6,
    name: 'Alok Mishra',
    image: alok,
    companylogo: cg,
    companyname: 'ConveGenius.AI',
    description: "I want to sincerely thank you for your outstanding mentorship throughout my journey in the Azure Data Engineering course at ForumDE. Initially, I struggled to keep up with the technical demands of the course, but your personalized feedback and step-by-step guidance turned things around for me.",
    aosDelay: "650",
  },
  {
    id: 8,
    name: 'Sourabh Das',
    image: das,
    companylogo: ey,
    companyname: 'EY',
    description: "I wanted to take a moment to thank you for your incredible mentorship throughout my Azure Data Engineering course journey. Your unwavering support and detailed explanations were key to deepening my understanding of the subject matter. Thanks to your guidance, I was able to pass challenging certifications and secure a promotion at my current organization.",
    aosDelay: "650",
  },
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2300,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex justify-center items-center py-14 mb-10">
      {googleFonts}
      <div className="container mx-auto">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-8">
          <h1
            data-aos="fade-up"
            data-aos-delay="400"
            className="px-3 mx-auto text-center underline text-5xl font-bold py-8"
          >
           Testimonials
          </h1>
          <a
            href="/testimonials"
            className="text-sm md:text-lg px-4 py-2 bg-gray-200 hover:bg-blue-200 text-black rounded-lg  transition ml-1"
          >
            View More
          </a>
        </div>

        {/* Testimonial Cards Section */}
        <div>
          <Slider {...settings}>
            {testimonialData.map((data) => {
              return (
                <div key={data.id} className="carousel-item">
                  <div className="flex flex-col gap-4 p-8 shadow-lg mx-4 rounded-xl bg-secondary/10 transition-transform transform hover:scale-105 hover:shadow-2xl">
                    {/* Upper Section */}
                    <div className="flex items-center justify-between gap-5">
                      <div className="flex items-center gap-5">
                        <img
                          src={data.image}
                          alt={data.name}
                          className="w-24 h-24 sm:w-32 sm:h-32 rounded-full border-4 border-white/70 transition-transform transform hover:scale-110"
                        />
                        <div>
                          <p className="text-xl sm:text-xl font-bold text-black mt-4">
                            {data.name}
                          </p>
                          <p className="text-sm text-gray-700">{data.companyname}</p>
                        </div>
                      </div>
                    </div>

                    {/* Bottom Section */}
                    <div className="py-6">
                      <p className="text-sm text-gray-500">{data.description}</p>
                      <p className="text-yellow-700 text-xl mt-2">★ ★ ★ ★ ★</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

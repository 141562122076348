import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore"; // Firestore imports
import { fireDB } from "../../Backend/Firebase/FirebaseConfigData"; // Firestore instance

const Stats = () => {
  const navigate = useNavigate(); // Hook for navigation

  // State for storing dynamic stats
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [stats, setStats] = useState<any[]>([]); // Use empty array initially

  // Fetch data from Firestore
  useEffect(() => {
    const fetchStatsData = async () => {
      try {
        const dbRef = collection(fireDB, "Hike Data"); // Firestore collection reference for "Hike Data"
        const snapshot = await getDocs(dbRef); // Fetch all documents from the collection

        if (!snapshot.empty) {
          const updatedStats = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              icon: data.icon || "🎓", // Use icon or default to "🎓"
              value: data.value || 0, // Use value or default to 0
              suffix: data.suffix || "+", // Use suffix or default to "+"
              label: data.label || "Unknown Label", // Use label or default to "Unknown Label"
            };
          });

          setStats(updatedStats); // Update the state with the fetched data
        } else {
          console.log("No data available in the 'Hike Data' collection.");
        }
      } catch (error) {
        console.error("Error fetching data from Firestore:", error);
      }
    };

    fetchStatsData(); // Fetch data when component mounts

    const intervalId = setInterval(fetchStatsData, 5000); // Refresh data every 5 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []); // Empty dependency array means this will run once on mount

  return (
    <div className="flex flex-wrap justify-center gap-8 p-8 bg-white">
      {stats.map((stat, index) => (
        <button
          onClick={() => navigate("/testimonials")} // Navigate to the testimonials page
          className="flex flex-col items-center bg-white shadow-md p-6 rounded-lg w-48 sm:w-64 md:w-80 lg:w-96 transition-transform transform hover:scale-105 hover:bg-blue-200 hover:text-black focus:outline-none"
          key={index} // Use index as key (or use a unique field if available in the data)
        >
          <div className="text-4xl transition-transform duration-500 hover:transform hover:scale-x-[-1]">
            {stat.icon}
          </div>
          <div className="text-3xl font-bold text-blue-600 mt-2 hover:text-white">
            <CountUp end={stat.value} duration={2} /> <span className="duration-1000">{stat.suffix}</span>
          </div>
          <div className="text-gray-600 mt-2 font-bold text-xl hover:text-white">{stat.label}</div>
        </button>
      ))}
    </div>
  );
};

export default Stats;

import React, { useState, useEffect } from "react";
import { MdMenu } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";  // Add the down arrow icon
import Logo from "../../assets/Header_LOGO.png";
import BottomHeader from "./BottomHeader/bottomHeader";
import AdvertisementPopup from "../PopupAd/popupAd";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions
import { fireDB } from "../../Backend/Firebase/FirebaseConfigData"; // Adjust the import path as needed

const Navbar: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [payNowDropdownOpen, setPayNowDropdownOpen] = useState(false);
  const [lottieUrl, setLottieUrl] = useState(""); // State to store the Lottie URL

  // Fetch the Lottie URL from Firestore
  useEffect(() => {
    const fetchLottieUrl = async () => {
      try {
        // Reference to the Firestore document
        const lottieRef = doc(fireDB, "OcassionGif", "current");
        const docSnap = await getDoc(lottieRef);

        if (docSnap.exists()) {
          // Set the Lottie URL from Firestore
          setLottieUrl(docSnap.data().lottieUrl || "");
        } else {
          console.log("No Lottie URL found in Firestore.");
        }
      } catch (error) {
        console.error("Error fetching Lottie URL:", error);
      }
    };

    fetchLottieUrl();
  }, []); // Empty dependency array to fetch data only once when the component mounts

  const HeaderMenu = [
    { id: 1, title: "Home", link: "/" },
    { id: 2, title: "About Us", link: "/aboutus" },
    { id: 3, title: "Achievers Wall", link: "/testimonials" },
    { id: 4, title: "Hire From Us", link: "/hirefromus" },
    { id: 5, title: "Corporate Training", link: "/corporate-training" },
    { id: 6, title: "Study Abroad", link: "https://studyabroad.forumde.in/" },
    { id: 7, title: "Jobs", link: "/Job" },
  ];

  const handleToggle = () => {
    setOpen(!open);
  };

  // Function to close the mobile menu after a click
  const handleMenuItemClick = () => {
    setOpen(false);
  };

  const handlePayNowClick = () => {
    setPayNowDropdownOpen(!payNowDropdownOpen);
  };

  return (
    <>
      <AdvertisementPopup />
      <nav className="shadow-sm bg-orange-200 border-t-2">
        <div className="mx-auto sm:px-6 lg:px-4">
          <div className="flex items-center py-2 justify-between">
            {/* Logo (Left Side) */}
            <div className="flex-shrink-0">
              <a href="/">
                <img
                  className="w-44 sm:w-48 md:w-48 lg:w-48 p-1"
                  src={Logo}
                  alt="logo"
                />
              </a>
            </div>

            {/* Lottie Animation */}
            {lottieUrl && (
              <iframe
                src={lottieUrl}
                className="h-24 w-24"
                title="Lottie Animation"
              ></iframe>
            )}

            {/* Desktop Menu (Hidden in tablet and below) */}
            <div className="hidden lg:flex flex-grow justify-center space-x-4 lg:pt-2 font-semibold">
              {HeaderMenu.map((data) => (
                <Link
                  key={data.id}
                  to={data.link}
                  className="hover:text-red-500 hover:border-b-2 transition-all duration-500 text-xl md:text-lg lg:text-xl"
                >
                  {data.title}
                </Link>
              ))}

              {/* Dashboard Button */}
              <a href="https://lms.forumde.in/login">
                <button className="bg-gradient-to-r from-blue-500 to-indigo-500  font-semibold py-2 px-4 ml-5 text-xl rounded-md text-white transition-all duration-300 hover:bg-indigo-600 hover:shadow-lg">
                  Dashboard
                </button>
              </a>

              {/* Pay Now Dropdown */}
              <div
                className="relative group"
                onMouseEnter={() => setPayNowDropdownOpen(true)}
                onMouseLeave={() => setPayNowDropdownOpen(false)}
              >
                <button className="bg-gradient-to-r from-green-500 to-teal-500 text-white py-2 px-2 rounded-md text-xl flex items-center">
                  Pay Now
                  {/* <IoIosArrowDown className="ml-2" />  Add down arrow icon */}
                </button>
                <AnimatePresence>
                  {payNowDropdownOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.3 }}
                      className="absolute left-0 bg-white w-40 z-50 rounded-lg shadow-lg mt-2"
                    >
                      <a
                        href="https://rzp.io/rzp/WveCwbxj"
                        className="block px-4 py-2 text-black hover:bg-orange-400"
                      >
                        Pay in INR
                      </a>
                      <a
                        href="https://rzp.io/rzp/8XYoIDxL"
                        className="block px-4 py-2 text-black hover:bg-orange-400"
                      >
                        Pay in USD
                      </a>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>

            {/* Mobile Menu Icon (Visible in tablet and below) */}
            <div className="lg:hidden" onClick={handleToggle}>
              <MdMenu className="text-4xl" />
            </div>
          </div>

          {/* Mobile Dropdown Menu */}
          <AnimatePresence>
            {open && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute top-16 left-0 w-full bg-white z-20 shadow-lg"
              >
                <div className="flex flex-col items-center justify-center space-y-4 py-6">
                  {HeaderMenu.map((data) => (
                    <Link
                      key={data.id}
                      to={data.link}
                      onClick={handleMenuItemClick} // Close menu on click
                      className="w-auto text-center py-1 px-6 rounded-md text-lg font-semibold text-gray-800 hover:bg-orange-200 transition-all duration-300 ease-in-out transform hover:scale-105"
                    >
                      {data.title}
                    </Link>
                  ))}
                </div>

                <div className="py-6 px-6 rounded-3xl">
                  <ul className="flex flex-col items-center gap-6 font-semibold text-base">
                    {/* Show only two buttons in mobile */}
                    <li>
                      <div className="flex flex-col gap-4">
                        <button className="bg-gradient-to-r from-blue-500 to-indigo-500 border-2 sm:w-40 py-2 px-6 text-base text-white hover:bg-indigo-600 hover:shadow-lg">
                          Dashboard
                        </button>
                        <button
                          className="bg-gradient-to-r flex from-green-500 to-teal-500 border-2 sm:w-40 py-2 px-6 text-base text-white hover:bg-teal-600 hover:shadow-lg"
                          onClick={handlePayNowClick} // Toggle Pay Now dropdown on click
                        >
                          Pay Now <IoIosArrowDown className="ml-2" /> {/* Down arrow icon */}
                        </button>
                        <AnimatePresence>
                          {payNowDropdownOpen && (
                            <motion.div
                              initial={{ opacity: 0, y: -10 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -10 }}
                              transition={{ duration: 0.3 }}
                              className=" left-0 bg-orange-100 w-fit z-50 duration-500 rounded-lg shadow-lg mt-2"
                            >
                              <a
                                href="https://rzp.io/rzp/WveCwbxj"
                                className="block px-4 py-2 text-black hover:bg-teal-500 hover:text-white"
                              >
                                Pay in INR
                              </a>
                              <a
                                href="https://rzp.io/rzp/8XYoIDxL"
                                className="block px-4 py-2 text-black hover:bg-teal-500 hover:text-white"
                              >
                                Pay in USD
                              </a>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    </li>
                  </ul>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>

      {/* Bottom Header */}
      <BottomHeader />
    </>
  );
};

export default Navbar;
import { useNavigate } from "react-router-dom";

const Mainpage_home = () => {
  const navigate = useNavigate();

  const navigateToEditDates = () => {
    navigate("/edit-dates");
  };

  const navigateToUpdateTestimonials = () => {
    navigate("/edit-testinomials");
  };

  const navigateToupdategif = () => {
    navigate("/update-ocassion");
  };

  const navigateToEditValues = () => {
    navigate("/edit-values");
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-blue-50 to-blue-100">
      {/* Header Section */}
      <header className="text-center mb-10">
        <h1 className="text-6xl font-bold text-gray-800 mb-6">
          Welcome to the Admin Panel
        </h1>
        <p className="text-lg text-gray-600">
          Manage your course dates, edit testimonials and hike values with ease.
        </p>
      </header>

      {/* Buttons */}
      <div className="flex flex-col items-center mb-10 space-y-6 max-w-md">
        <button
          onClick={navigateToEditDates}
          className=" w-full bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-blue-700 transform transition-all duration-300 hover:scale-105"
        >
          Edit Courses Dates
        </button>

        <button
          onClick={navigateToUpdateTestimonials}
          className="w-full bg-gradient-to-r from-orange-500 to-red-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-green-700 transform transition-all duration-300 hover:scale-105"
        >
          Edit Testimonials
        </button>
        <button
          onClick={navigateToupdategif}
          className="w-full bg-gradient-to-r from-purple-500 to-pink-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-purple-700 transform transition-all duration-300 hover:scale-105"
        >
          Update Ocassion
        </button>
        <button
          onClick={navigateToEditValues}
          className="w-full bg-gradient-to-r from-cyan-500 to-blue-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-blue-700 transform transition-all duration-300 hover:scale-105"
        >
          Edit Hike Values
        </button>

        {/* <button
          onClick={navigateToEditValues}
          className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-blue-700 transform transition-all duration-300 hover:scale-105"
        >
          Edit Hike Values
        </button> */}
        {/* <button
          onClick={navigateToEditTestimonials}
          className="bg-gradient-to-r from-green-500 to-teal-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-green-700 transform transition-all duration-300 hover:scale-105"
        >
          Edit Testimonials
        </button> */}
      </div>
    </div>
  );
};

export default Mainpage_home;

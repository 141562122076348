import { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database"; // Import Firebase methods
import { rtdb } from "../../../../Backend/Firebase/FirebaseConfigData"; // Correct path to FirebaseConfigData
import logo from "../../../../assets/Header_LOGO.png"; // Assuming correct path to the logo

const ViewCorporateData = () => {
  interface FormData {
    id: string;
    fullName: string;
    title: string;
    email: string;
    company: string;
    countryCode: string;
    mobile: string;
    message: string;
    timestamp: string;
  }

  const [formData, setFormData] = useState<FormData[]>([]);

  useEffect(() => {
    // Reference to the Firebase Realtime Database path where the form data is stored
    const dataRef = ref(rtdb, "Corporate Training");

    // Fetch the data from Firebase Realtime Database
    onValue(dataRef, (snapshot) => {
      const data = snapshot.val();
      const formattedData = data
        ? Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }))
        : [];

      // Sort data by timestamp in descending order (most recent first)
      formattedData.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

      // Format the timestamp to show only the date and time with a dash
      const formattedDataWithTimestamp = formattedData.map((entry) => {
        const date = new Date(entry.timestamp);
        const formattedTimestamp = `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
        return { ...entry, timestamp: formattedTimestamp };
      });

      setFormData(formattedDataWithTimestamp); // Set the formatted data to state
    });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-r from-white to-blue-50 py-8">
      {/* Header */}
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 flex flex-col lg:flex-row justify-between items-center mb-12">
        <div className="mb-6 lg:mb-0">
          <img src={logo} alt="Logo" className="h-20 w-auto object-contain" />
        </div>
        <div className="text-center mb-6 lg:mb-0">
          <h1 className="text-5xl font-extrabold text-gray-800">ForumDE Admin</h1>
        </div>
      </div>

      <hr className="border-t-4 border-gray-300 mb-14" />

      <h1 className="text-4xl font-bold text-center text-gray-700 mb-15">Corporate Data Form</h1>

      {/* Table Section */}
      <div className="container mx-auto px-4 max-w-7xl my-11 mb-20 bg-white shadow-xl rounded-xl overflow-x-auto">
        <table className="min-w-full table-auto text-sm text-gray-700">
          <thead className="bg-gradient-to-r from-blue-600 to-blue-500">
            <tr>
              <th className="text-2xl px-6 py-4 text-center text-white font-semibold">S.No</th>
              <th className="text-2xl px-6 py-4 text-center text-white font-semibold">Full Name</th>
              <th className="text-2xl px-6 py-4 text-center text-white font-semibold">Title</th>
              <th className="text-2xl px-6 py-4 text-center text-white font-semibold">Email</th>
              <th className="text-2xl px-6 py-4 text-center text-white font-semibold">Company</th>
              <th className="text-2xl px-6 py-4 text-center text-white font-semibold">Mobile</th>
              <th className="text-2xl px-6 py-4 text-center text-white font-semibold">Country Code</th>
              <th className="text-2xl px-6 py-4 text-center text-white font-semibold">Message</th>
              <th className="text-2xl px-6 py-4 text-center text-white font-semibold">Timestamp</th>
            </tr>
          </thead>

          {/* Dynamically Render Table Body */}
          <tbody className="divide-y-4 divide-gray-300">
            {formData.map((entry, index) => (
              <tr key={entry.id} className="transition duration-300 ease-in-out hover:bg-gray-50">
                <td className="text-xl px-6 py-4 text-center text-gray-800 font-semibold">{index + 1}</td>
                <td className="text-xl px-6 py-4 text-center text-gray-800 font-semibold">{entry.fullName}</td>
                <td className="text-xl px-6 py-4 text-center text-gray-800 font-semibold">{entry.title}</td>
                <td className="text-xl px-6 py-4 text-center text-gray-800 font-semibold">{entry.email}</td>
                <td className="text-xl px-6 py-4 text-center text-gray-800 font-semibold">{entry.company}</td>
                <td className="text-xl px-6 py-4 text-center text-gray-800 font-semibold">{entry.mobile}</td>
                <td className="text-xl px-6 py-4 text-center text-gray-800 font-semibold">{entry.countryCode}</td>
                <td className="text-xl px-6 py-4 text-center text-gray-800 font-semibold">{entry.message}</td>
                <td className="text-xl px-6 py-4 text-center text-gray-800 font-semibold">{entry.timestamp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewCorporateData;

import Marquee from 'react-fast-marquee';


// Import your logos

import tcs from '../../assets/working_logo/tcs.png';
import accenture from '../../assets/working_logo/accenture.png';
import wns from '../../assets/working_logo/WNS.png';
import publicis from '../../assets/working_logo/publicis.png';
import ait from '../../assets/working_logo/ait.png';
import saama from '../../assets/working_logo/saama.png';
import axa from '../../assets/working_logo/axa.png';
import cognizant from '../../assets/working_logo/cognizant.png';
import pwc from '../../assets/working_logo/pwc.png';
import capegemini from '../../assets/working_logo/capegemini.png';
import genpact from '../../assets/working_logo/genpact.png';
import circlek from '../../assets/working_logo/Circlek.png';
import kpmg from '../../assets/working_logo/kpmg.png';
import ncs from '../../assets/working_logo/ncs.png';
import inxite from '../../assets/working_logo/inxiteout.png';

// Define a type for the logo data
interface Logo {
  src: string;
  alt: string;
}

// Create an array of logos
const logos: Logo[] = [
  { src: tcs, alt: 'TCS' },
  { src: accenture, alt: 'Accenture' },
  { src: wns, alt: 'WNS' },
  { src: publicis, alt: 'Publicis' },
  { src: ait, alt: 'AIT' },
  { src: saama, alt: 'Saama' },
  { src: axa, alt: 'AXA' },
  { src: cognizant, alt: 'Cognizant' },
  { src: pwc, alt: 'PwC' },
  { src: capegemini, alt: 'Capgemini' },
  { src: genpact, alt: 'Genpact' },
  { src: circlek, alt: 'Circle K' },
  { src: kpmg, alt: 'KPMG' },
  { src: ncs, alt: 'NCS' },
  { src: inxite, alt: 'Inxite' },
 
];

const Company_Marquee = () => {
  return (
    <div className="text-center py-6 mx-10 rounded-xl">
      <h1 className="font-bold underline p-3 text-2xl md:text-5xl lg:text-5xl" data-aos="fade-up" data-aos-delay="1200">
      Our Achievers Are Working In
      </h1>
      <div data-aos="fade-up" data-aos-delay="1000" className="py-4 rounded-xl w-full h-full">
        <Marquee speed={100} gradient={false} direction='right'>
          {logos.map((logo, index) => (
            <div key={index} className="flex items-center mx-4">
              <img src={logo.src} alt={logo.alt} className="h-fit w-fit m-6 p-4" />
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default Company_Marquee;
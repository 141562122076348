import { useState } from 'react';
import { rtdb } from '../../../../Backend/Firebase/FirebaseConfigData'; // Import Firebase Realtime Database instance
import { ref, set } from 'firebase/database'; // Import ref and set functions from Firebase Realtime Database
import Coorporatetop from './coporatecontent';

const CorporateTrainingForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    title: '',
    email: '',
    company: '',
    countryCode: '+91',
    mobile: '',
    message: '',
    agreed: false,
  });

  const [emailError, setEmailError] = useState<string>('');
  const [mobileError, setMobileError] = useState<string>('');

  // Country codes included
  const countryCodes = [
    { code: '+91', country: 'IN' },
    { code: '+1', country: 'US' },
    { code: '+44', country: 'UK' },
    { code: '+61', country: 'AU' },
    { code: '+86', country: 'CN' },
    { code: '+81', country: 'JP' },
    { code: '+49', country: 'DE' },
    { code: '+33', country: 'FR' },
  ];

  // Function to sanitize full name for use in Firebase path
  const sanitizeName = (name: string) => {
    return name.replace(/[^a-zA-Z0-9]/g, '_');
  };

  // Validate email
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email) || !email.endsWith('.com')) {
      setEmailError('Please enter a valid email address');
      return false;
    }
    setEmailError('');
    return true;
  };

  // Validate mobile number
  const validateMobile = (mobile: string, countryCode: string) => {
    // Ensure only numbers are allowed for all countries
    const numericRegex = /^\d+$/;
    if (!numericRegex.test(mobile)) {
      setMobileError('Only numeric characters are allowed');
      return false;
    }

    // Specific validation for India
    if (countryCode === '+91') {
      if (mobile.length !== 10) {
        setMobileError('Please enter a valid mobile number');
        return false;
      }
    } else {
      // Validation for other countries
      if (mobile.length < 9 || mobile.length > 11) {
        setMobileError('Please enter a valid mobile number');
        return false;
      }
    }

    setMobileError('');
    return true;
  };

  // Handle email blur event
  const handleEmailBlur = () => {
    validateEmail(formData.email);
  };

  // Handle mobile number change event
  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow only numeric input
    const numericValue = inputValue.replace(/\D/g, '');

    // Update the mobile field with the numeric value
    setFormData({ ...formData, mobile: numericValue });

    // Validate the mobile number length based on the country code
    validateMobile(numericValue, formData.countryCode);
  };

  // Handle mobile number blur event
  const handleMobileBlur = () => {
    validateMobile(formData.mobile, formData.countryCode);
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!formData.agreed) {
      alert('Please accept the terms and privacy policy');
      return;
    }

    const isEmailValid = validateEmail(formData.email);
    const isMobileValid = validateMobile(formData.mobile, formData.countryCode);

    if (!isEmailValid || !isMobileValid) {
      return; // Stop submission if validation fails
    }

    try {
      // Sanitize the full name to create a valid Firebase path
      const sanitizedFullName = sanitizeName(formData.fullName);

      // Create a reference in Firebase using the sanitized full name
      const formRef = ref(rtdb, `Corporate Training/${sanitizedFullName}`);

      // Add form data to Firebase Realtime Database under the full name key
      await set(formRef, {
        fullName: formData.fullName,
        title: formData.title,
        email: formData.email,
        company: formData.company,
        countryCode: formData.countryCode,
        mobile: formData.mobile,
        message: formData.message,
        timestamp: new Date().toISOString(), // Add timestamp for submission time
      });

      alert("We'll get back to you soon!!");

      // Reset form data
      setFormData({
        fullName: '',
        title: '',
        email: '',
        company: '',
        countryCode: '+91',
        mobile: '',
        message: '',
        agreed: false,
      });

      window.location.reload();
    } catch (error) {
      console.error('Error submitting form data:', error);
      alert('There was an error submitting your form. Please try again later.');
    }
  };

  return (
    <div className="min-h-screen py-8">
      {/* Corporate Top Image */}
      <Coorporatetop />

      <div className="max-w-2xl mx-auto px-6">
        <div className="bg-white shadow-xl rounded-xl p-8 md:p-12 border border-blue-800">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Corporate Training Inquiry
          </h2>

          <form onSubmit={handleSubmit} className="space-y-8">
            {/* Name and Title row */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="relative">
                <label htmlFor="fullName" className="block text-sm text-gray-700 mb-2">
                  Full Name *
                </label>
                <input
                  type="text"
                  id="fullName"
                  placeholder="Full Name *"
                  className="w-full px-4 py-3 rounded-xl border-2 border-blue-500 focus:border-blue-600 focus:outline-none transition-all duration-300"
                  required
                  value={formData.fullName}
                  onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                />
              </div>
              <div className="relative">
                <label htmlFor="title" className="block text-sm text-gray-700 mb-2">
                  Title *
                </label>
                <input
                  type="text"
                  id="title"
                  placeholder="Title *"
                  className="w-full px-4 py-3 rounded-xl border-2 border-blue-500 focus:border-blue-600 focus:outline-none transition-all duration-300"
                  required
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                />
              </div>
            </div>

            {/* Email field */}
            <div className="relative">
              <label htmlFor="email" className="block text-sm text-gray-700 mb-2">
                Email Address *
              </label>
              <input
                type="email"
                id="email"
                placeholder="Email Address *"
                className={`w-full px-4 py-3 rounded-xl border-2 ${
                  emailError ? 'border-red-500' : 'border-blue-500'
                } focus:outline-none transition-all duration-300`}
                required
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                onBlur={handleEmailBlur} // Validate email on blur
              />
              {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
            </div>

            {/* Mobile Number with Country Code */}
            <div className="relative">
              <label htmlFor="mobile" className="block text-sm text-gray-700 mb-2">
                Mobile Number *
              </label>
              <div className="flex flex-col gap-4 md:flex-row">
                <select
                  className="w-full md:w-1/4 px-4 py-3 rounded-xl border-2 border-blue-500 focus:outline-none transition-all duration-300"
                  value={formData.countryCode}
                  onChange={(e) => setFormData({ ...formData, countryCode: e.target.value })}
                >
                  {countryCodes.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.code} ({country.country})
                    </option>
                  ))}
                </select>
                <input
                  type="tel"
                  id="mobile"
                  placeholder="Mobile Number *"
                  className={`flex-1 px-4 py-3 rounded-xl border-2 ${
                    mobileError ? 'border-red-500' : 'border-blue-500'
                  } focus:outline-none transition-all duration-300`}
                  required
                  value={formData.mobile}
                  onChange={handleMobileChange} // Restrict non-numeric input
                  onBlur={handleMobileBlur} // Validate mobile on blur
                  maxLength={formData.countryCode === '+91' ? 10 : 11} // Set max length based on country code
                />
              </div>
              {mobileError && <p className="text-red-500 text-sm mt-1">{mobileError}</p>}
            </div>

            {/* Company Name */}
            <div className="relative">
              <label htmlFor="company" className="block text-sm text-gray-700 mb-2">
                Company Name *
              </label>
              <input
                type="text"
                id="company"
                placeholder="Company Name *"
                className="w-full px-4 py-3 rounded-xl border-2 border-blue-500 focus:outline-none transition-all duration-300"
                required
                value={formData.company}
                onChange={(e) => setFormData({ ...formData, company: e.target.value })}
              />
            </div>

            {/* Message */}
            <div className="relative">
              <label htmlFor="message" className="block text-sm text-gray-700 mb-2">
                Request Message *
              </label>
              <textarea
                id="message"
                placeholder="Request Message *"
                rows={4}
                className="w-full px-4 py-3 rounded-xl border-2 border-blue-500 focus:outline-none transition-all duration-300"
                required
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              />
            </div>

            {/* Terms and Conditions */}
            <div className="flex items-start gap-2 mt-4">
              <input
                type="checkbox"
                id="terms"
                className="mt-1"
                checked={formData.agreed}
                onChange={(e) => setFormData({ ...formData, agreed: e.target.checked })}
                required
              />
              <label htmlFor="terms" className="text-sm text-gray-600">
                By providing your contact details, you agree to our{' '}
                <a href="/Terms" className="text-blue-600 hover:text-blue-700 underline">
                  Terms of Use
                </a>{' '}
                &{' '}
                <a href="/Private" className="text-blue-600 hover:text-blue-700 underline">
                  Privacy Policy
                </a>
              </label>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold py-4 rounded-xl hover:from-blue-600 hover:to-blue-700 transform hover:-translate-y-0.5 transition-all duration-200 shadow-lg hover:shadow-xl"
            >
              Get In Touch
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CorporateTrainingForm;
import { useState } from 'react';

const InstructionsDropdown = () => {
  // State to manage the visibility of the dropdown
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      {/* Down button */}
      <button
        onClick={toggleDropdown}
        className="text-blue-500 mt-2 inline-flex items-center"
      >
        <span>Click here for instructions</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="ml-2 w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          strokeWidth="2"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {/* Dropdown Content */}
      {isOpen && (
        <div
          className="dropdown-content text-sm text-gray-600 mt-2 bg-white shadow-md rounded-md p-4"
        >
          <p>
            To upload your GIF and obtain a URL, follow these steps:
            <br />
            1. Visit{" "}
            <a
              href="https://cloudinary.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Cloudinary
            </a>
            , a cloud-based image and video management platform.
            <br />
            2. Sign in or create an account if you don’t have one yet.
            <br />
            3. Once logged in, navigate to the <strong>Media Library</strong> section.
            <br />
            4. Click the <strong>Upload</strong> button and select your GIF file to upload from your device.
            <br />
            5. After the upload completes, click on the GIF image to open it.
            <br />
            6. In the GIF’s details page, click on the <strong>'Copy URL'</strong> button to copy the direct link to your clipboard.
            <br />
            7. Return to this page and paste the URL you copied into the appropriate field.
            <br />
            That’s it! Your GIF will be ready to display.
          </p>
        </div>
      )}
    </div>
  );
};

export default InstructionsDropdown;

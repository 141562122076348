// eslint-disable @next/next/no-img-element ;
"use client";

import Lottie from 'lottie-react';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import gif from '../../assets/gif/azure 2.gif'
import download from '../../assets/Logo/download.json';
import FlashMessage from '../FlashComponent/flashcomponent';

const Details = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const downloadOptions = {
    loop: true,
    autoplay: true,
    animationData: download,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="pt-2 lg:pt-5 flex flex-col lg:flex-row relative p-6 min-h-[12%] w-full bg-orange-50">
      <div className=" w-full lg:w-2/3 p-4 mx-4 pt-0" data-aos="fade-up" data-aos-delay="500">
        <h2 className="font-extrabold pb-1 text-2xl lg:text-3xl text-gray-800">
          FREE!! 100-Day Self-Study Plan to Learn Data Engineering
        </h2>
        <br />
        <div className="w-full">
          <p className="text-lg lg:text-xl py-4 text-gray-700">
            We have developed a 100-day self-study plan for students aspiring to become Azure Data Engineers. Based on our interactions with many learners, we’ve noticed a lack of clear direction on what and how to study. Our plan sets daily targets to guide students effectively towards their goal.
          </p>
          <h2 className="pt-4 text-xl lg:text-2xl text-gray-800">
            Follow the plan and prepare yourself to crack the Azure Data Engineering Interview.
          </h2>
          <br />
          <p className="text-xl lg:text-xl py-2 pt-2 text-gray-700">
            Use the <b>Download</b> button below to get the plan.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row justify-center lg:justify-start items-center space-y-4 lg:space-y-0 lg:space-x-4 mt-6 w-full">
          <a
            href="https://drive.google.com/file/d/1k_-5W_gNZko6vX6GqJVdAZxzok9jl1Op/view?usp=drive_link"
            download="100-Day-Data-Engineering-Plan"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <button className="flex items-center justify-center px-10 py-6 rounded-xl bg-red-400 border-3 border-black text-white text-xl lg:text-lg  h-10">
              Download
              <Lottie
                {...downloadOptions}
                className="ml-2"
                style={{ height: '50px', width: '50px' }}
              />
            </button>
          </a>
        </div>
      </div>
      <div
  className="w-full lg:w-1/3  justify-center items-center mt-6 lg:mt-0"
  data-aos="fade-up"
  data-aos-delay="500"
>
  <div className=" items-center gap-4">
    <FlashMessage />
    <img
      src={gif}
      alt="Azure Data Engineering GIF"
      className="rounded-lg w-96 h-96 lg:pl-3"
    />
  </div>
</div>

    </div>
  );
};

export default Details;

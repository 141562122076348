import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState, useRef } from "react";
import { fireDB } from "../../../Backend/Firebase/FirebaseConfigData";
import { useNavigate } from 'react-router-dom';

interface BlogPost {
  id: string;
  title: string;
  index: number; // Add the index field to sort blogs
}

const BottomHeader = () => {
  const [blogs, setBlogs] = useState<BlogPost[]>([]);  // State for blog posts
  const [error, setError] = useState<string | null>(null);  // Error handling state
  const [loading, setLoading] = useState(true);  // Loading state
  const [currentIndex, setCurrentIndex] = useState(0);  // State to track the current visible blog index
  const blogsPerView = 3;  // Number of blogs to show at once
  const containerRef = useRef<HTMLDivElement>(null);  // Ref for the container

  const navigate = useNavigate();

  const handleHeading = (id: string) => {
    navigate(`/bloginfo/${id}`);
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);  // Set loading to true when fetching starts
      try {
        const headingCollection = collection(fireDB, 'blogPost');
        const headingSnapShot = await getDocs(headingCollection);
        const headings = headingSnapShot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as BlogPost[];

        // Sort the blogs based on the 'index' field
        const sortedBlogs = headings.sort((a, b) => a.index - b.index);

        setBlogs(sortedBlogs);  // Set blogs data
        setLoading(false);  // Set loading to false when data is fetched successfully
      } catch (err) {
        setError('Failed to load');  // Set error message
        console.log(err);
        setLoading(false);  // Set loading to false even if there's an error
      }
    };

    fetchBlogs();
  }, []);  // Empty dependency array ensures this runs once when the component mounts

  // If loading, show loading message
  if (loading) {
    return <div className="text-center bg-orange-100 rounded-lg">Loading ...</div>;
  }

  // If there was an error, show error message
  if (error) {
    return <div className="text-center p-5 text-red-500">{error}</div>;
  }

  // Handle swipe gesture
  let touchStartX = 0;
  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX = e.touches[0].clientX;  // Get initial touch position
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!containerRef.current) return;
    const touchMoveX = e.touches[0].clientX;
    const touchDelta = touchStartX - touchMoveX;  // Calculate movement distance

    if (Math.abs(touchDelta) > 50) {  // Minimum swipe distance threshold
      if (touchDelta > 0) {
        // Swipe left (move right in the list)
        setCurrentIndex((prev) => Math.min(blogs.length - blogsPerView, prev + 1));
      } else {
        // Swipe right (move left in the list)
        setCurrentIndex((prev) => Math.max(0, prev - 1));
      }
      touchStartX = touchMoveX;  // Update the touch start position after action
    }
  };

  const handleTouchEnd = () => {
    // You can reset any variables or finalize the position after swipe ends if needed
  };

  // Function to update sliding position based on current index
  const translateXValue = -(currentIndex * (100 / blogsPerView));

  // Function to move left
  const moveLeft = () => {
    setCurrentIndex((prev) => Math.max(0, prev - 1));  // Prevent moving left beyond the first item
  };

  // Function to move right
  const moveRight = () => {
    setCurrentIndex((prev) => Math.min(blogs.length - blogsPerView, prev + 1));  // Prevent moving right beyond the last item
  };

  // Check if content overflows (to determine if the buttons should be visible)
  const isOverflowing = blogs.length > blogsPerView;

  return (
    <nav className="bottomheader w-full bg-orange-100 py-2 px-4 md:px-8 shadow-lg">
      {/* Wrapper for content and buttons */}
      <div className="relative flex items-center justify-between">
        {/* Left arrow button (only visible on mobile screens and when overflow exists) */}
        {isOverflowing && (
          <button
            onClick={moveLeft}
            className={`absolute left-0 top-1/2 transform -translate-y-1/2 bg-transparent text-gray-700 hover:text-green-500 p-2 rounded-full md:hidden ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{ zIndex: 2 }}
            disabled={currentIndex === 0} // Disable the button when on the first page
          >
            &lt;
          </button>
        )}

        {/* Blog Titles Container */}
        <div
          className="flex overflow-x-auto no-scrollbar transition-all duration-300 ease-in-out whitespace-nowrap"
          ref={containerRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          style={{ transform: `translateX(${translateXValue}%)` }}
        >
          {blogs.map((blog) => {
            const { id, title } = blog;
            return (
              <div key={id} className="text-center mb-2 md:mb-0 px-2">
                <h1
                  className="cursor-pointer font-semibold text-lg text-gray-800 hover:text-green-500 hover:underline transition-all duration-200"
                  onClick={() => handleHeading(id)}
                >
                  {title}
                </h1>
              </div>
            );
          })}
        </div>

        {/* Right arrow button (only visible on mobile screens and when overflow exists) */}
        {isOverflowing && (
          <button
            onClick={moveRight}
            className={`absolute right-0 top-1/2 transform -translate-y-1/2 bg-transparent text-gray-700 hover:text-green-500 p-2 rounded-full md:hidden ${currentIndex >= blogs.length - blogsPerView ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{ zIndex: 2 }}
            disabled={currentIndex >= blogs.length - blogsPerView} // Disable the button when on the last page
          >
            &gt;
          </button>
        )}
      </div>
    </nav>
  );
};

export default BottomHeader;

import { useState, useEffect } from "react";
import adposter1 from "../../../../assets/Ad_Poster/Ad_Poster.jpeg";
import adposter2 from "../../../../assets/Ad_Poster/Ad_Poster02.jpg";
import adposter3 from "../../../../assets/Ad_Poster/Ad_Poster03.jpg";
import adposter4 from "../../../../assets/Ad_Poster/Ad_Poster04.jpg";
import adposter5 from "../../../../assets/Ad_Poster/Ad_Poster05.jpg";

const StudyAbroad = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const images = [
    adposter1,
    adposter2,
    adposter3,
    adposter4,
    adposter5
  ];

  const openModal = (image: string) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gradient-to-b from-orange-100 via-orange-50 to-gray-900">
        Loading...
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-b from-white via-orange-50 to-orange-300 min-h-screen">
      <div className="container mx-auto  py-8">
        {/* Create a 2-column grid layout */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative group w-full h-[70vh] sm:h-[80vh] lg:h-[90vh] rounded-lg overflow-hidden shadow-lg transition-shadow duration-300 cursor-pointer"
              onClick={() => openModal(image)} // Open the modal on click
            >
              <img
                src={image}
                alt={`Study Abroad Poster ${index + 1}`}
                className="absolute inset-0 w-full h-full object-contain group-hover:scale-110 transition-transform duration-300"
              />
              {/* Hover Overlay */}
              <div className="absolute inset-0 bg-black/40 group-hover:bg-black/60 transition-all duration-300 flex items-center justify-center">
                <h2 className="text-white font-bold text-xl sm:text-3xl lg:text-4xl">Click to View</h2>
              </div>
            </div>
          ))}
        </div>

        {/* Modal to show full screen image */}
        {selectedImage && (
          <div
            className="fixed inset-0 bg-black/70 flex justify-center items-center z-50"
            onClick={closeModal} // Close the modal if clicked outside the image
          >
            <div
              className="relative w-screen h-screen"
              onClick={(e) => e.stopPropagation()} // Prevent closing the modal when clicking inside the image
            >
              <img
                src={selectedImage}
                alt="Full Screen Poster"
                className="object-contain w-screen h-screen"
              />
              <button
                className="absolute top-4 right-4 text-white   rounded text-5xl font-bold"
                onClick={closeModal} // Close the modal when clicking the close button
              >
                X
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudyAbroad;

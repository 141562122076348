import { useNavigate } from 'react-router-dom';

const ViewFormData = () => {
  const navigate = useNavigate();

 
  const navigateToViewDBAForm = () => {
    navigate('/View-Form-Data/View-DBA-Form');
  };
  const navigateToViewHDForm = () => {
    navigate('/View-Form-Data/View-HD-Form');
  };
  const navigateToHireFromUS = () => {
    navigate('/View-Form-Data/View-Hire-From-Us');
  };

  const navigateToCorporateTraining = () => {
    navigate('/View-Form-Data/View-Corporate-Training');
  };


  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-blue-50 to-blue-100">
      {/* Header Section */}
      <header className="text-center mb-10">
        <h1 className="text-6xl font-bold text-gray-800 mb-6">
          Welcome to the Admin Panel
        </h1>
        <p className="text-lg text-gray-600">
         View the data submitted by users
        </p>
      </header>

      {/* Buttons */}
      <div className="flex flex-col items-center mb-10 space-y-6 max-w-md">
      
        <button
          onClick={navigateToViewDBAForm}
          className="w-full bg-gradient-to-r from-purple-500 to-pink-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-purple-700 transform transition-all duration-300 hover:scale-105"
        >
          Doctor of Business Administration Form
        </button>
        <button
          onClick={navigateToViewHDForm}
          className="w-full bg-gradient-to-r from-green-500 to-teal-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-green-700 transform transition-all duration-300 hover:scale-105"
        >
          Honorary Doctorate Form
        </button>

        <button
          onClick={navigateToHireFromUS}
          className="w-full bg-gradient-to-r from-orange-500 to-red-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-green-700 transform transition-all duration-300 hover:scale-105"
        >
          Hire From Us Data
        </button>

        <button
          onClick={navigateToCorporateTraining}
          className="w-full bg-gradient-to-r from-cyan-500 to-blue-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-blue-700 transform transition-all duration-300 hover:scale-105"
        >
          Corporate Training Data
        </button>

        {/* <button
          onClick={navigateToEditValues}
          className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-blue-700 transform transition-all duration-300 hover:scale-105"
        >
          Edit Hike Values
        </button> */}
        {/* <button
          onClick={navigateToEditTestimonials}
          className="bg-gradient-to-r from-green-500 to-teal-600 text-white py-3 px-8 rounded-xl text-lg shadow-md hover:bg-green-700 transform transition-all duration-300 hover:scale-105"
        >
          Edit Testimonials
        </button> */}
        
      </div>
    </div>
  );
}

export default ViewFormData;

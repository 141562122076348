import { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import AdPosterimg from "../../assets/Ad_Poster/Ad_Poster03.jpg";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { fireDB } from "../../Backend/Firebase/FirebaseConfigData"; // Adjust the import path as needed

const AdvertisementPopup = () => {
  const [showAd, setShowAd] = useState(false);
  const [occasionImageUrl, setOccasionImageUrl] = useState(""); // State to store the fetched GIF URL
  const location = useLocation();

  // Fetch the GIF URL from Firestore
  useEffect(() => {
    const fetchGifUrl = async () => {
      try {
        // Reference to the Firestore document
        const gifRef = doc(fireDB, "OcassionGif", "current");
        const docSnap = await getDoc(gifRef);

        if (docSnap.exists()) {
          // Set the GIF URL from Firestore
          setOccasionImageUrl(docSnap.data().gifUrl);
        } else {
          console.log("No GIF URL found in Firestore.");
        }
      } catch (error) {
        console.error("Error fetching GIF URL:", error);
      }
    };

    fetchGifUrl();
  }, []);

  useEffect(() => {
    // Only show ad if we're on the home page
    if (location.pathname === "/") {
      setShowAd(true);
    } else {
      setShowAd(false);
    }
  }, [location]);

  const closeAd = () => {
    setShowAd(false);
  };

  return (
    <>
      <AnimatePresence>
        {showAd && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="bg-transparent rounded-lg w-full lg:w-full sm:w-11/12 md:w-4/5 mx-auto overflow-hidden"
            >
              {/* Close Button */}
              <button
                className="absolute top-4 right-4 w-10 h-10 flex items-center justify-center bg-red-500 text-white rounded-full hover:bg-red-600 z-10 transition-all duration-300"
                onClick={closeAd}
              >
                <MdClose className="text-2xl" />
              </button>

              {/* Popup Content */}
              <div className="overflow-hidden rounded-lg p-2 relative flex flex-col items-center justify-center mt-1">
                {/* Occasion GIF */}
                {occasionImageUrl && (
                  <div className="w-72 flex justify-center mb-4">
                    <img
                      src={occasionImageUrl}
                      alt="Occasion Banner"
                      className="w-full max-w-[800px] h-auto object-contain rounded-lg"
                    />
                  </div>
                )}

                {/* Lottie Animations */}
                <div className="flex flex-col sm:flex-row items-center justify-center space-y-8 sm:space-x-8 sm:space-y-0 w-full">
                  <div className="w-full sm:w-1/3 flex justify-center items-center">
                    <iframe
                      src="https://lottie.host/embed/8f67fbc0-2707-4a18-a192-e10c6dfa15cd/f1r8lT6xoS.lottie"
                      className="w-full h-full"
                      title="Lottie Animation 1"
                    ></iframe>
                    <iframe
                      src="https://lottie.host/embed/92c2e67d-cd8a-4784-9925-d05ef7b561e6/kfVEMIZJNL.lottie"
                      className="w-full h-full"
                      title="Lottie Animation 2"
                    ></iframe>
                  </div>

                  {/* Ad Poster Image */}
                  <a href="https://studyabroad.forumde.in/" className="flex justify-center items-center mt-6 w-full sm:w-1/3">
                    <img
                      src={AdPosterimg}
                      alt="Doctor of Business Administration"
                      className="w-full h-auto object-contain mb-1 rounded-lg border-4 border-white shadow-lg"
                      style={{
                        maxHeight: "70vh",
                        width: "100%",
                      }}
                    />
                  </a>

                  <div className="w-full sm:w-1/3 flex justify-center items-center">
                    <iframe
                      src="https://lottie.host/embed/22f655b3-5060-43c2-a087-034848e6d0bc/nUe2gRCzfs.lottie"
                      className="w-full h-full"
                      title="Lottie Animation 3"
                    ></iframe>
                    <iframe
                      src="https://lottie.host/embed/2873299f-8108-4f22-90b2-b137347bf639/SNAo9XBVoa.lottie"
                      className="w-full h-full"
                      title="Lottie Animation 4"
                    ></iframe>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AdvertisementPopup;
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import Posterimg from "../../assets/Ad_Poster/Ad_Poster03.jpg";
import { ArrowRight } from "lucide-react";

// Data for the Programs
const programsData = [
  {
    id: 1,
    title: "Doctor of Business Administration",
    description:
      "Advance your career with our comprehensive DBA program. Develop advanced research skills and business expertise through rigorous coursework and dissertation research.",
    duration: "18 - 36 months",
    imageUrl: Posterimg,
    link: "/programs/doctorate-in-business-administration",
    borderColor: "border-blue-500",
  },
  {
    id: 2,
    title: "Honorary Doctorate",
    description:
      "Receive an Honorary Doctorate to recognize your outstanding contributions to your field. This degree is given to individuals for their exceptional work in their fields. This prestigious program provides an honorary degree from a globally recognized institution.",
    imageUrl: Posterimg,
    link: "/programs/honorary-doctorate",
    borderColor: "border-purple-500",
  },
];

// Program Card Component
const ProgramCard = ({
  title,
  description,
  duration,
  imageUrl,
  link,
  borderColor,
}: {
  title: string;
  description: string;
  duration?: string;
  imageUrl: string;
  link: string;
  borderColor: string;
}) => {
  const handleCardClick = () => {
    window.location.href = link;
  };

  return (
    <div
      data-aos="fade-up"
      className={`group bg-white rounded-lg shadow-md hover:shadow-xl transition-all duration-300 cursor-pointer overflow-hidden border-2 ${borderColor} hover:scale-[1.02]`}
      onClick={handleCardClick}
    >
      <div className="relative">
        <img
          src={imageUrl}
          alt={title}
          className="w-full h-48 object-cover"
        />
        <div className={`absolute bottom-0 left-0 w-full h-1 ${borderColor} bg-opacity-75`} />
      </div>
      <div className="p-8">
        <h3
          data-aos="fade-up"
          className="text-2xl font-semibold group-hover:text-blue-600 transition-colors duration-300 mb-4"
        >
          {title}
        </h3>
        <p data-aos="fade-up" className="text-gray-600 mb-6">
          {description}
        </p>
        {duration && (
          <div className="flex items-center text-gray-600 mb-6">
            <span className="font-medium">Duration:</span>
            <span className="ml-2">{duration}</span>
          </div>
        )}
        <div className="flex justify-between items-center">
          <span
            className={`font-medium transition-colors duration-300 ${borderColor.replace(
              "border-",
              "text-"
            )}`}
          >
            Learn More
          </span>
          <ArrowRight
            className={`w-5 h-5 transform group-hover:translate-x-1 transition-transform duration-300 ${borderColor.replace(
              "border-",
              "text-"
            )}`}
          />
        </div>
      </div>
    </div>
  );
};

const DoctoralPrograms = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h2 data-aos="fade-up" className="text-5xl font-bold mb-16 text-center underline">
        Doctoral Programs
      </h2>
      <div className="grid md:grid-cols-2 gap-12">
        {programsData.map((program) => (
          <ProgramCard
            key={program.id}
            title={program.title}
            description={program.description}
            duration={program.duration}
            imageUrl={program.imageUrl}
            link={program.link}
            borderColor={program.borderColor}
          />
        ))}
      </div>
    </div>
  );
};

export default DoctoralPrograms;

import React from "react";
import { ref, push, set } from "firebase/database"; // Import Firebase methods
import { rtdb } from "../../Backend/Firebase/FirebaseConfigData";
import Logo from "../../assets/Header_LOGO.png";

interface FormData {
  fullName: string;
  email: string;
  countryCode: string;
  mobile: string;
  agreed: boolean;
}

interface HD_PopupProps {
  showPopup: boolean;
  onClose: () => void;
  formData: FormData;
  onFormDataChange: (data: FormData) => void;
  isSubmitting: boolean;
  countryCodes: { code: string; country: string }[];
  emailError?: string;
  mobileError?: string;
  onEmailBlur?: () => void;
  onMobileBlur?: () => void;
}

const HDPopupForm: React.FC<HD_PopupProps> = ({
  showPopup,
  onClose,
  formData,
  onFormDataChange,
  isSubmitting,
  countryCodes,
  emailError,
  mobileError,
  onEmailBlur,
  onMobileBlur,
}) => {
  if (!showPopup) return null;

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.agreed) {
      alert("Please accept the terms and privacy policy");
      return;
    }

    const newFormRef = push(ref(rtdb, "HD Form")); // Create a new form entry 
    try {
      await set(newFormRef, {
        fullName: formData.fullName,
        email: formData.email,
        countryCode: formData.countryCode,
        mobile: formData.mobile,
        timestamp: new Date().toISOString(),
      });
      alert("Your request has been submitted successfully!");
      onFormDataChange({
        fullName: "",
        email: "",
        countryCode: "+91",
        mobile: "",
        agreed: false,
      });
      onClose(); // Close the popup after submission
    } catch (error) {
      console.error("Error submitting form data:", error);
      alert("There was an error submitting your form. Please try again later.");
    }
  };

  // Restrict mobile number input to numbers only
  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "");
    onFormDataChange({ ...formData, mobile: value });
  };

  // Set maxLength for mobile number based on country code
  const getMobileMaxLength = (countryCode: string) => {
    if (countryCode === "+91") {
      return 10; // India: exactly 10 digits
    } else {
      return 11; // Other countries: maximum 11 digits
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
      <div className="bg-white/95 backdrop-blur-sm p-8 rounded-2xl shadow-xl w-full max-w-5xl flex flex-col lg:flex-row gap-6 relative">
        {/* Logo Section */}
        <div className="w-full lg:w-1/3 p-4 flex justify-center items-center">
          <img
            src={Logo}
            alt="Company Logo"
            className="max-w-full h-auto rounded-lg shadow-lg"
          />
        </div>

        {/* Form Section */}
        <div className="w-full lg:w-2/3">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl"
          >
            ✕
          </button>
          <h1 className="text-3xl font-bold text-center text-orange-500 mb-8">
            Request More Information
          </h1>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 gap-6">
              <div className="space-y-2">
                <label htmlFor="fullName" className="block text-sm text-gray-600">
                  Full Name *
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="Full Name"
                  autoComplete="name"
                  className="w-full px-4 py-3 rounded-xl border-2 border-orange-100 focus:border-orange-400 focus:outline-none transition-colors"
                  required
                  value={formData.fullName}
                  onChange={(e) =>
                    onFormDataChange({ ...formData, fullName: e.target.value })
                  }
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="email" className="block text-sm text-gray-600">
                  Email Address *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  autoComplete="email"
                  className={`w-full px-4 py-3 rounded-xl border-2 ${
                    emailError ? "border-red-500" : "border-orange-100"
                  } focus:border-orange-400 focus:outline-none transition-colors`}
                  required
                  value={formData.email}
                  onChange={(e) =>
                    onFormDataChange({ ...formData, email: e.target.value })
                  }
                  onBlur={onEmailBlur}
                />
                {emailError && (
                  <p className="text-red-500 text-sm mt-1">{emailError}</p>
                )}
              </div>

              <div className="space-y-2">
                <div className="flex gap-4">
                  <div className="w-40">
                    <label
                      htmlFor="countryCode"
                      className="block text-sm text-gray-600 mb-2"
                    >
                      Country Code *
                    </label>
                    <select
                      id="countryCode"
                      name="countryCode"
                      autoComplete="tel-country-code"
                      className="w-full px-3 py-3 rounded-xl border-2 border-orange-100 focus:border-orange-400 focus:outline-none transition-colors bg-white"
                      value={formData.countryCode}
                      onChange={(e) =>
                        onFormDataChange({
                          ...formData,
                          countryCode: e.target.value,
                        })
                      }
                    >
                      {countryCodes.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.code} ({country.country})
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="mobile"
                      className="block text-sm text-gray-600 mb-2"
                    >
                      Mobile Number *
                    </label>
                    <input
                      type="tel"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile Number"
                      autoComplete="tel"
                      className={`w-full px-4 py-3 rounded-xl border-2 ${
                        mobileError ? "border-red-500" : "border-orange-100"
                      } focus:border-orange-400 focus:outline-none transition-colors`}
                      required
                      value={formData.mobile}
                      onChange={handleMobileChange}
                      onBlur={onMobileBlur}
                      maxLength={getMobileMaxLength(formData.countryCode)}
                    />
                    {mobileError && (
                      <p className="text-red-500 text-sm mt-1">{mobileError}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-start gap-2 mt-6">
              <input
                type="checkbox"
                id="terms"
                name="terms"
                className="mt-1"
                checked={formData.agreed}
                onChange={(e) =>
                  onFormDataChange({ ...formData, agreed: e.target.checked })
                }
                required
              />
              <label htmlFor="terms" className="text-sm text-gray-600">
                By providing your contact details, you agree to our{" "}
                <a
                  href="/Terms"
                  className="text-orange-600 hover:text-orange-700 underline"
                >
                  Terms of Use
                </a>{" "}
                &{" "}
                <a
                  href="/Private"
                  className="text-orange-600 hover:text-orange-700 underline"
                >
                  Privacy Policy
                </a>
              </label>
            </div>

            <button
              type="submit"
              name="submitButton"
              disabled={isSubmitting}
              className="w-full bg-gradient-to-r from-orange-500 to-orange-600 text-white font-semibold py-4 rounded-xl hover:from-orange-600 hover:to-orange-700 transform hover:-translate-y-0.5 transition-all duration-200 shadow-lg hover:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HDPopupForm;

import React, { useState } from "react";

const FloatingButton: React.FC = () => {
  const [menuState, setMenuState] = useState<"closed" | "main" | "call">(
    "closed"
  );

  const phoneNumbers = [
    { label: "IND", number: "+919801830173" },
    { label: "US", number: "+1524901033" },
    { label: "UK", number: "+441213871751" },
  ];

  const toggleMenu = () => {
    setMenuState((prev) => (prev === "closed" ? "main" : "closed"));
  };

  const openCallMenu = () => {
    setMenuState("call");
  };

  const copyNumber = (number: string) => {
    navigator.clipboard
      .writeText(number)
      .then(() => {
        alert(`${number} copied to clipboard!`);
      })
      .catch((err) => {
        console.error(`Failed to copy number ${number}:`, err);
      });
  };

  return (
    <div className="fixed bottom-4 right-4  flex flex-col items-end space-y-2 pb-10 z-10 md:pb-12">
      {/* Nested Menus */}
      {menuState === "main" && (
        <div className="space-y-4  ">
          {/* Chat with us */}
          <a
            href="https://wa.me/+919801830173?text=hi"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 bg-red-500 text-white rounded-full px-4 py-2 shadow-lg hover:bg-green-700 focus:outline-none"
            aria-label="Chat with us"
          >
            <div className="bg-white text-green-500 rounded-full p-2">
              {/* WhatsApp Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 36 36"
                className="w-6 h-6"
              >
                <path d="M16 0c-8.837 0-16 7.163-16 16 0 2.822.733 5.532 2.114 7.922l-1.38 5.056 5.172-1.353c2.355 1.337 5.037 2.074 7.943 2.074 8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 29c-2.621 0-5.145-.685-7.373-1.98l-.529-.312-3.073.805.82-2.998-.342-.546c-1.283-2.047-1.941-4.402-1.941-6.869 0-7.72 6.28-14 14-14s14 6.28 14 14-6.28 14-14 14z" />
                <path d="M23.492 19.066c-.403-.201-2.381-1.177-2.749-1.31-.368-.133-.637-.201-.905.201s-1.037 1.31-1.271 1.577c-.233.268-.469.302-.872.1-.403-.201-1.697-.626-3.233-1.997-1.196-1.068-2.005-2.39-2.237-2.793-.233-.403-.025-.621.176-.823.181-.18.403-.468.604-.704.201-.234.268-.402.402-.671.134-.268.067-.503-.033-.704-.1-.201-.905-2.179-1.241-2.993-.328-.793-.663-.686-.905-.686-.235-.014-.503-.017-.772-.017s-.704.1-1.075.503c-.368.402-1.4 1.366-1.4 3.329s1.435 3.857 1.635 4.12c.201.268 2.82 4.314 6.837 6.044.957.413 1.705.66 2.287.845.96.307 1.834.265 2.524.161.77-.115 2.381-.973 2.719-1.913.338-.94.338-1.744.236-1.913-.101-.168-.368-.268-.772-.469z" />
              </svg>
            </div>
            <span>Chat with us</span>
          </a>

          {/* Call us */}
          <button
            className="flex items-center space-x-2 bg-red-500 text-white rounded-full px-4 py-2 shadow-lg hover:bg-blue-700 focus:outline-none"
            onClick={openCallMenu}
            aria-label="Call us"
          >
            <div className="bg-white text-blue-500 rounded-full p-2">
              {/* Phone Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                className="w-6 h-6"
              >
                <path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57a1 1 0 0 0-.97.2l-2.2 2.2a15.93 15.93 0 0 1-7.07-7.07l2.2-2.2a1 1 0 0 0 .2-.97A11.93 11.93 0 0 1 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1C3 14.15 9.85 21 19 21c.55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1z" />
              </svg>
            </div>
            <span>Call us</span>
          </button>
        </div>
      )}

      {menuState === "call" && (
        <div className="space-y-2">
          {phoneNumbers.map((phone, index) => (
            <button
              key={index}
              className="flex items-center space-x-2 bg-red-500 text-white rounded-full px-4 py-2 shadow-lg hover:bg-blue-900 focus:outline-none"
              onClick={() => copyNumber(phone.number)}
              aria-label={`Copy ${phone.label} number`}
            >
              <div className="bg-white text-blue-700 rounded-full p-2">
                {/* Phone Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57a1 1 0 0 0-.97.2l-2.2 2.2a15.93 15.93 0 0 1-7.07-7.07l2.2-2.2a1 1 0 0 0 .2-.97A11.93 11.93 0 0 1 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1C3 14.15 9.85 21 19 21c.55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1z" />
                </svg>
              </div>
              <span>
                {phone.label}: {phone.number}
              </span>
            </button>
          ))}
        </div>
      )}

      {/* Main Button */}
      <button
        className="bg-red-500 text-white rounded-full p-4 shadow-lg focus:outline-none"
        onClick={toggleMenu}
        aria-label="Toggle Menu"
      >
        {menuState === "closed" ? (
          <svg
          aria-hidden="true" focusable="false" className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em"
        >
          <path d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          className="w-6 h-6"
        >
          <path d="M18 6L6 18M6 6l12 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        </svg>
        )}
      </button>
    </div>
  );
};

export default FloatingButton;

import coorporate from '../../../../assets/coorporate/hire2 (1).jpg';

const Hiretop = () => {
  return (
    <div className="w-full">
      {/* Image Section */}
      <div className="relative w-full">
        <img
          src={coorporate}
          alt="Get Hired"
          className="w-full h-[700px] object-cover mt-1"
        />
        {/* Overlay Effect */}
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-40"></div>
      </div>

      {/* Description Section */}
      <div className="bg-white py-12 px-6 sm:px-12 text-center transform transition-all duration-300 ease-in-out">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6 sm:mb-8">
          Hire From Us
        </h2>
        <p className="text-lg sm:text-xl text-gray-700 leading-relaxed mb-4">
        At ForumDE, we specialize in providing top-tier IT professionals who are equipped with the latest skills and expertise. Our comprehensive training programs ensure that our candidates are ready to meet the demands of today’s fast-evolving tech landscape.
        </p>
        <p className="text-lg sm:text-xl text-gray-700 leading-relaxed mb-6">
        Whether you're looking to expand your team or fill key positions, ForumDE offers highly skilled, job-ready professionals who can drive success and innovation within your organization. Partner with us and discover the talent that will propel your business forward.
        </p>

        
      </div>
    </div>
  );
};

export default Hiretop;

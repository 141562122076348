// data/TeamsData.js
import Nandini from '../../../../../assets/team_detail/nandini.jpg';
import Saurav from '../../../../../assets/team_detail/saurabh.png';
// import Shubhangi from '../../../../../assets/team_detail/subhangi.png';
import Ashish from '../../../../../assets/team_detail/ashish.jpg';
import Sonu from '../../../../../assets/team_detail/sonu.jpg';
import Nitin from '../../../../../assets/team_detail/nitin.png';
import Manish from '../../../../../assets/team_detail/manish.png';
import Vivek from '../../../../../assets/team_detail/VIVEK.jpg';


const TeamsData = [
  {
    id: 1,
    name: "Sonu Singh",
    team: "Chief Marketing Officer",
    description: "",
    img: Sonu
  },
  {
    id: 2,
    name: "Nitin Mukesh",
    team: "Marketing Head",
    description: "",
    img: Nitin
  },
  {
    id: 3,
    name: "Saurav Ranjan",
    team: "Marketing Team",
    description: "",
    img: Saurav
  },
  // {
  //   id: 4,
  //   name: "Shubhangi Jha",
  //   team: "Sales Team",
  //   description: "",
  //   img: Shubhangi
  // },
  {
    id: 5,
    name: "Nandini Kumari",
    team: "Tech Team",
    description: "",
    img: Nandini
  },
  {
    id: 6,
    name: "Ashish Parihar",
    team: "Tech Team",
    description: "",
    img: Ashish
  },
  {
    id: 6,
    name: "Manish",
    team: "Tech Team",
    description: "",
    img: Manish
  },
  {
    id: 6,
    name: "Vivek Shastri",
    team: "Sales Manager",
    description: "",
    img: Vivek
  }
];

export default TeamsData;

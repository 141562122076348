
export const data = [
    {
        question: "What is ForumDE?",
        answer: "ForumDE is a technical training and professional certification course provider,dedicated to providing high-quality education and hands-on training in various technical/professional domains.",
        IsAccordionOpen: false
     },
    {
        question: "What courses does ForumDE offer?",
        answer: "ForumDE offers a range of programs, like Data Engineering,Data Science,MBA In Artificial Intelligence,MBA in Data Scince,Doctor of Business Administration(DBA),etc.",
        IsAccordionOpen: false
    },
    {
        question: "Who can enroll in ForumDE programs?",
        answer: "Anyone with a keen interest in technology and learning new skills! Whether you’re a beginner, a student, or a professional, there’s a program for you.Contact our team on the number mentioned in the website to know more about the programs.",
        IsAccordionOpen: false
    },
    {
        question: "How do I enroll in a course?",
        answer: "You can enroll by visiting our website at https://forumde.in/ or contacting our team.",
        IsAccordionOpen: false
    },
    // {
    //     question: "What are the prerequisites for enrolling in a program? ",
    //     answer: "Prerequisites vary by course.Do visit our website at https://forumde.in/ or contact our team to know more.",
    //     IsAccordionOpen: false
    // },
    // {
    //     question: "Is there an age limit for enrollment?  ",
    //     answer: "No, we welcome learners of all ages!",
    //     IsAccordionOpen: false
    // },
    // {
    //     question: "What is the duration of the courses?",
    //     answer: "Different courses have different course duration.Do visit our website at https://forumde.in/ or contact our team to know more.",
    //     IsAccordionOpen: false
    // },
    {
        question: "Do the programs include hands-on training?",
        answer: "Yes, wherever possible we emphasize practical, hands-on learning through projects, labs, and real-world simulations.",
        IsAccordionOpen: false
    },
    {
        question: "Are certifications provided after course completion? ",
        answer: "Yes, we provide certifications upon successful completion of our courses.",
        IsAccordionOpen: false
    },
    // {
    //     question: "How much do the courses cost? ",
    //     answer: "The fees vary depending on the course. For detailed pricing, visit https://forumde.in/ or contact our team.",
    //     IsAccordionOpen: false
    // },
    {
        question: " Do you provide job placement assistance?",
        answer: "Yes, we help students with job resume building, and interview preparation.",
        IsAccordionOpen: false
    },
    // {
    //     question: "Can I get a refund if I'm unable to continue?",
    //     answer: "Our refund policy is detailed on https://forumde.in/. Please review it or contact us for clarification.",
    //     IsAccordionOpen: false
    // },
    // {
    //     question: "Will I have access to resources after completing the course?",
    //     answer: "Every course has different duration for accessing the course.Visit https://forumde.in/ or contact our team for details",
    //     IsAccordionOpen: false
    // },
    // {
    //     question: "How can I contact ForumDE for more details?",
    //     answer: "Visit our website https://forumde.in/ or contact our team on the numbers mentioned in the website.",
    //     IsAccordionOpen: false
    // },

];
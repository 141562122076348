import { useState } from 'react';

const LottieInstructionsDropdown = () => {
  // State to manage the visibility of the dropdown
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      {/* Down button */}
      <button
        onClick={toggleDropdown}
        className="text-blue-500 mt-2 inline-flex items-center"
      >
        <span>Click here for LottieFiles instructions</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="ml-2 w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          strokeWidth="2"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {/* Dropdown Content */}
      {isOpen && (
        <div
          className="dropdown-content text-sm text-gray-600 mt-2 bg-white shadow-md rounded-md p-4"
        >
          <p>
            To obtain the Lottie animation URL, follow these detailed steps:
            <br />
            1. Go to{" "}
            <a
              href="https://lottiefiles.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              LottieFiles
            </a>
            , a popular platform for finding, downloading, and embedding Lottie animations. 
            It's a great resource for animations that work well with web and mobile applications.
            <br />
            2. If you don’t have an account, you’ll need to <strong>sign up</strong> for free to access all features. If you already have an account, simply <strong>log in</strong>.
            <br />
            3. Once logged in, use the <strong>search bar</strong> at the top of the homepage to look for the animation you want. You can search by keyword, tags, or animation category (e.g., "loading", "UI elements", etc.).
            <br />
            4. After finding the animation, click on its thumbnail to view its details page.
            <br />
            5. On the animation's page, click on the <strong>'Save to Workspace'</strong> button. This saves the animation to your LottieFiles workspace for future use or modifications.
            <br />
            6. In the workspace, you will see an option to <strong>enable the Asset CDN</strong>. Asset CDN is a feature that allows you to access the animation files directly via a Content Delivery Network, making the integration process smoother and faster.
            <br />
            7. Once you've enabled the Asset CDN, you can find the embedded URL under the "Embed" section. Click the <strong>'Copy URL'</strong> button to copy the animation's direct URL to your clipboard.
            <br />
            8. After copying the URL, go back to this page and paste it in the input field. This URL will allow you to embed the Lottie animation into your project.
            <br />
            <strong>Important Notes:</strong>
            <ul>
              <li>Make sure the animation you choose is appropriate for your project in terms of size and complexity.</li>
              <li>Some animations might require attribution (check the licensing on LottieFiles).</li>
              <li>Ensure the Asset CDN is enabled for smooth animation performance and accessibility.</li>
            </ul>
            That’s it! You are now ready to use the animation in your website or app.
          </p>
        </div>
      )}
    </div>
  );
};

export default LottieInstructionsDropdown;

import React, { useState, useEffect } from 'react';
// Import your Firebase Firestore instance
import { collection, getDocs } from 'firebase/firestore';
import { fireDB } from '../../../../../Backend/Firebase/FirebaseConfigData';

// Load Google Fonts in the HTML head (index.html)
const googleFonts = (
  <link
    href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Noto+Serif:wght@400;700&display=swap"
    rel="stylesheet"
  />
);

interface Testimonial {
  name: string;
  companyname: string;
  description: string;
  imageUrl: string;
  rating: number;
}

const TestimonialsCards: React.FC = () => {
  const [expanded, setExpanded] = useState<string | null>(null);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

  // Fetch testimonials data from Firestore
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        // Reference to the "testimonials" collection in Firestore
        const querySnapshot = await getDocs(collection(fireDB, 'testimonials'));

        // Mapping through the documents and extracting data
        const fetchedTestimonials = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            name: data.name,
            companyname: data.companyName,
            description: data.description,
            imageUrl: data.imageUrl || '', // Adjust field name as per your Firebase setup
            rating: data.rating,
          };
        });

        // Setting the testimonials data in state
        setTestimonials(fetchedTestimonials);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };

    fetchTestimonials();
  }, []);

  // Handle the read more functionality
  const handleReadMore = (name: string) => {
    setExpanded(expanded === name ? null : name);
  };

  return (
    <div className="py-3 px-4 sm:px-8">
      {googleFonts} {/* Inject Google Fonts */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {testimonials.map((data) => (
          <div
            key={data.name}
            className="bg-gray-800 shadow-lg rounded-lg p-4 flex flex-col items-center transition-transform transform hover:scale-105 hover:shadow-2xl hover:bg-gray-900"
          >
            {/* Profile Image */}
            <img
              src={data.imageUrl}
              alt={data.name}
              className="w-24 h-24 sm:w-32 sm:h-32 rounded-full border-4 border-white/70 transition-transform transform hover:scale-110"
              width={128}
              height={128}
            />
            {/* Name */}
            <h1
              className={`text-xl sm:text-xl font-bold text-white text-center mt-4`}
              style={{ fontFamily: 'Montserrat, sans-serif' }}
            >
              {data.name}
            </h1>
            {/* Company */}
            <div className="flex items-center space-x-2 mt-2">
              <h2
                className={`text-lg sm:text-xl text-white font-semibold`}
                style={{ fontFamily: 'Noto Serif, serif' }}
              >
                {data.companyname}
              </h2>
            </div>
            {/* Rating */}
            <div className="text-yellow-400 text-xl mt-2">★ ★ ★ ★ ★</div>
            {/* Description */}
            <p
              className={`text-gray-300 text-lg sm:text-lg mt-2 transition-all duration-300 ${expanded === data.name ? 'max-h-screen' : 'max-h-16 overflow-hidden'}`}
              style={{ lineHeight: '1.6', fontFamily: 'Noto Serif, serif' }}
            >
              {data.description}
            </p>
            {/* Read More Button */}
            <button
              className="text-orange-400 hover:text-orange-600 mt-3 transition-all duration-300"
              onClick={() => handleReadMore(data.name)}
            >
              {expanded === data.name ? 'Read Less' : 'Read More'}
            </button>

           
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialsCards;

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { fireDB } from "../../../Backend/Firebase/FirebaseConfigData";  // Import your Firebase configuration
import DotLoader from "../../../Additional_components/Loading/Dot Loader/Loader";  // Assuming DotLoader is your loading spinner
import Suggest from "./Sugest";
import Comment from '../../Comment/Commentblog';
import DisqusComments from '../../Comment/Commentblog';
const articlePost = {
  slug: 'example-post-slug',
  title: 'Example Post Title'
};

// Type definition for blog data
interface BlogInfo {
  id: string;
  title: string;
  date: string;
  content: string;
  category: string;
}

const BlogInfoPage = () => {
  const params = useParams();
  const navigate = useNavigate(); // Hook for navigation
  const [getBlogs, setGetBlogs] = useState<BlogInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch blog data with improved error handling and retry mechanism
  const getAllBlogs = async () => {
    if (!params.id) {
      setError("Blog ID is missing.");
      setLoading(false);
      return;
    }

    const blogId = params.id as string;
    setLoading(true);
    let retryAttempts = 3;

    const fetchData = async () => {
      try {
        const blogDoc = await getDoc(doc(fireDB, "blogPost", blogId));
        if (blogDoc.exists()) {
          const blogData = blogDoc.data() as BlogInfo;
          setGetBlogs(blogData);
        } else {
          setError("Blog not found");
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.message.includes("Failed to fetch") && retryAttempts > 0) {
          retryAttempts -= 1;
          console.log(`Retrying... (${3 - retryAttempts} attempts left)`);
          await fetchData();  // Retry the fetch
        } else if (error.message.includes("Failed to fetch")) {
          setError("Network error: Unable to fetch data. Please check your connection.");
        } else {
          setError("Error fetching blog.");
        }
      } finally {
        setLoading(false);
      }
    };

    await fetchData();
  };

  useEffect(() => {
    getAllBlogs();
    window.scrollTo(0, 0); // Scroll to top on new blog page
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  // Create markup for rendering HTML content
  const createMarkup = (content: string) => {
    return { __html: content };
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <DotLoader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10 text-red-500">
        {error}
        <button
          onClick={() => navigate("/blog")}
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
        >
          Go back to blogs
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-6 px-1 sm:px-4 lg:px-5 bg-gray-50 flex flex-col">
      <div className="flex flex-wrap lg:flex-nowrap justify-between gap-8 flex-grow">
        {/* Sidebar (only visible on larger screens) */}
        <div className="w-full lg:w-[20%] mt-5 lg:mt-0 order-last lg:order-first">
          <div className="mb-8">
            <Suggest /> {/* Suggestion Box Component */}
          </div>
        </div>

        {/* Main Blog Content (80% of the screen) */}
        <div className="w-full lg:w-[80%] lg:border-l border-gray-300 lg:pl-8">
          <section className="max-w-7xl mx-auto bg-white rounded-lg p-8 shadow-md">
            {/* Blog Header Section */}
            <div className="flex flex-col lg:flex-row justify-between items-start mb-8">
              <div>
                <h1 className="text-4xl md:text-5xl font-bold text-gray-800 leading-snug">
                  {getBlogs?.title}
                </h1>
                <p className="mt-4 text-sm text-gray-500">{getBlogs?.date}</p>
              </div>

              {/* Category Badge */}
              <div className="mt-4 lg:mt-0">
                <span className="px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded-full shadow-md">
                  {getBlogs?.category}
                </span>
              </div>
            </div>

            {/* Divider */}
            <div className="border-b border-gray-300 mb-8" />

            {/* Blog Content */}
            <div
              className="content text-lg text-gray-700 leading-relaxed mb-8"
              dangerouslySetInnerHTML={createMarkup(getBlogs?.content || "No content available.")}
            />

            {/* Back Link */}
            <div className="text-center">
              <button
                onClick={() => navigate("/blog")}
                className="px-6 py-2 text-sm text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition duration-200"
              >
                Back
              </button>
            </div>
          </section>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Comment />
      <DisqusComments post={articlePost} />
    </div>
  );
};

export default BlogInfoPage;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import logo from "../../../../assets/Header_LOGO.png";
import { doc, setDoc, collection, onSnapshot } from "firebase/firestore"; // Firestore imports
import { fireDB } from "../../../../Backend/Firebase/FirebaseConfigData";

// Define UpdatedValuesTable component
const UpdatedValuesTable: React.FC = () => {
   
  const [data, setData] = useState<any[]>([]); // State for storing data fetched from Firebase
  const [isAdding, setIsAdding] = useState(false); // State for toggling between Add and Update form
  const [newHikeData, setNewHikeData] = useState({
    Label: "",
    value: 0,
    icon: "",
    suffix: "+",
  });

  // Fetch data from Firestore on component mount
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(fireDB, "Hike Data"), (snapshot) => {
      const hikeData: any[] = [];
      snapshot.forEach((doc) => {
        hikeData.push({ id: doc.id, ...doc.data() });
      });
      setData(hikeData); // Set data from Firestore to state
    });

    // Clean up listener when component unmounts
    return () => unsubscribe();
  }, []);

  // Handle the value change for updating hike data
  const handleValueChange = (id: string, field: string, value: string) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  // Update the hike data in Firestore
  const handleUpdateValue = async (id: string, updatedItem: any) => {
    try {
      const postRef = doc(fireDB, "Hike Data", id); // Use the document ID for update
      // Update only the changed fields
      const updatedFields: any = {};
      if (updatedItem.Label !== undefined) updatedFields.label = updatedItem.Label;
      if (updatedItem.value !== undefined) updatedFields.value = updatedItem.value;
      if (updatedItem.icon !== undefined) updatedFields.icon = updatedItem.icon;
      if (updatedItem.suffix !== undefined) updatedFields.suffix = updatedItem.suffix;

      await setDoc(postRef, updatedFields, { merge: true }); // Merge to update only changed fields

      setData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, ...updatedItem } : item
        )
      );
      alert("Data has been updated successfully.");
    } catch (error) {
      console.error("Error updating value in Firestore:", error);
      alert("There was an error updating the value in Firestore. Please try again.");
    }
  };

  // Handle adding a new hike
  const handleAddHike = async () => {
    // Validate input fields before proceeding
    if (!newHikeData.Label || !newHikeData.icon || isNaN(newHikeData.value)) {
      alert("Please provide a valid label, icon, and value.");
      return;
    }

    // Sanitize label to ensure it's a valid Firestore document ID (e.g., remove invalid characters)
    const sanitizedLabel = newHikeData.Label.replace(/[^\w\s]/gi, "_");

    try {
      const newDocRef = doc(fireDB, "Hike Data", sanitizedLabel); // Use sanitized label as doc ID
      await setDoc(newDocRef, {
        label: newHikeData.Label,
        value: newHikeData.value,
        icon: newHikeData.icon,
        suffix: newHikeData.suffix,
      });

      setData((prevData) => [
        ...prevData,
        {
          id: sanitizedLabel, // Use sanitized label as ID
          Label: newHikeData.Label,
          value: newHikeData.value,
          icon: newHikeData.icon,
          suffix: newHikeData.suffix,
        },
      ]);
      setIsAdding(false); // Hide the form after submission
      setNewHikeData({
        Label: "",
        value: 0,
        icon: "🎓",
        suffix: "+",
      });
      alert("New hike has been added successfully.");
    } catch (error) {
      console.error("Error adding hike:", error);
      alert("There was an error adding the hike. Please check your inputs or try again later.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-white to-blue-50 py-8">
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 flex flex-col lg:flex-row justify-between items-center mb-12">
        <div className="mb-6 lg:mb-0">
          <img src={logo} alt="Logo" className="h-20 w-auto object-contain" />
        </div>
        <div className="text-center mb-6 lg:mb-0">
          <h1 className="text-5xl font-extrabold text-gray-800">ForumDE Admin</h1>
        </div>
      </div>

      <hr className="border-t-4 border-gray-300 mb-14" />

      <h1 className="text-5xl font-bold text-center text-gray-700 mb-15">Update or Add Hike Values</h1>

      {/* Action Buttons */}
      <div className="text-center mb-10">
        <button
          className="text-xl font-bold text-white bg-green-500 px-4 py-2 rounded mr-4"
          onClick={() => setIsAdding(true)}
        >
          Add New Hike
        </button>
        <button
          className="text-xl font-bold text-white bg-blue-500 px-4 py-2 rounded"
          onClick={() => setIsAdding(false)}
        >
          Update Existing Hike Data
        </button>
      </div>

      {/* Add New Hike Form */}
      {isAdding && (
        <div className="container mx-auto px-4 max-w-7xl my-11 mb-20 bg-white shadow-xl rounded-xl p-8">
          <h2 className="text-3xl font-bold mb-4">Add New Hike</h2>
          <div className="flex flex-col gap-4">
            <label>Icon</label>
            <input
              type="text"
              value={newHikeData.icon}
              onChange={(e) => setNewHikeData({ ...newHikeData, icon: e.target.value })}
              className="border border-gray-300 rounded p-2"
            />

            <label>Label</label>
            <input
              type="text"
              value={newHikeData.Label}
              onChange={(e) => setNewHikeData({ ...newHikeData, Label: e.target.value })}
              className="border border-gray-300 rounded p-2"
            />

            <label>Value</label>
            <input
              type="number"
              value={newHikeData.value}
              onChange={(e) => setNewHikeData({ ...newHikeData, value: Number(e.target.value) })}
              className="border border-gray-300 rounded p-2"
            />

            <label>Suffix</label>
            <input
              type="text"
              value={newHikeData.suffix}
              onChange={(e) => setNewHikeData({ ...newHikeData, suffix: e.target.value })}
              className="border border-gray-300 rounded p-2"
            />

            <button
              onClick={handleAddHike}
              className="text-xl font-bold text-white bg-green-500 px-4 py-2 rounded mt-4"
            >
              Submit New Hike
            </button>
          </div>
        </div>
      )}

      {/* Table Section */}
      {!isAdding && (
        <div className="container mx-auto px-4 max-w-7xl my-11 mb-20 bg-white shadow-xl rounded-xl overflow-x-auto">
          <table className="min-w-full table-auto text-sm text-gray-700">
            <thead className="bg-gradient-to-r from-blue-600 to-blue-500">
              <tr>
                <th className="text-3xl px-6 py-4 text-center text-white font-semibold">S.No</th>
                <th className="text-3xl px-6 py-4 text-center text-white font-semibold">Icon</th>
                <th className="text-3xl px-6 py-4 text-center text-white font-semibold">Label</th>
                <th className="text-3xl px-6 py-4 text-center text-white font-semibold">Current Value</th>
                <th className="text-3xl px-6 py-4 text-center text-white font-semibold">Updated Value</th>
                <th className="text-3xl px-6 py-4 text-center text-white font-semibold">Suffix</th>
                <th className="text-3xl px-6 py-4 text-center text-white font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y-4 divide-gray-300">
              {data.map((item, index) => (
                <tr key={item.id} className="transition duration-300 ease-in-out hover:bg-gray-50">
                  <td className="text-2xl px-6 py-4 text-center text-gray-800 font-semibold">{index + 1}</td>
                  <td className="text-2xl px-6 py-4 text-center text-gray-800">
                  <h1 className="text-4xl">{item.icon}</h1>
                  </td>
                  <td className="text-2xl px-6 py-4 text-center text-gray-800">
                    <input
                      type="text"
                      value={item.label}
                      onChange={(e) => handleValueChange(item.id, "Label", e.target.value)}
                      className="text-xl border border-gray-300 rounded p-2"
                    />
                  </td>
                  <td className="text-2xl px-6 py-4 text-center text-gray-800">
                    <input
                      type="number"
                      value={item.value}
                      onChange={(e) => handleValueChange(item.id, "value", e.target.value)}
                      className="text-xl border border-gray-300 rounded p-2"
                    />
                  </td>
                  <td className="text-2xl px-6 py-4 text-center text-gray-800">
                   <h1>{item.suffix}</h1>
                  </td>
                  <td className="px-6 py-4 text-center">
                    <button
                      className="text-xl font-bold text-white bg-blue-500 px-4 py-2 rounded"
                      onClick={() => handleUpdateValue(item.id, item)}
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UpdatedValuesTable;

"use client";

import Accordion from './Accordion';
import { data } from './Data';

const FAQ = () => {
    return (
        <div className="p-10 bg-gray-100 flex items-center">
            <section className="p-6 sm:p-12 rounded-lg max-w-7xl w-full sm:w-3/5 mx-auto shadow-xl border-2 bg-orange-200">
                <h1 className="text-3xl sm:text-4xl font-bold text-center text-black mb-6">
                    Frequently Asked Questions
                </h1>
                <div className="space-x-0"> {/* Reduced spacing */}
                    {data.map((d, i) => (
                        <Accordion
                            answer={d.answer}
                            question={d.question}
                            IsAccordingOpen={d.IsAccordionOpen}
                            key={i}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
}

// eslint-disable-next-line react-refresh/only-export-components
export default FAQ;

import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { fireDB } from "../../../Backend/Firebase/FirebaseConfigData"; // Your Firebase config
import { useState, useEffect } from "react";
import SubSuggest from "./subheadingsugestion";
import Comment from '../../Comment/commentblogmain';
import DisqusComments from '../../Comment/commentblogmain';
const articlePost = {
  slug: 'example-post-slug',
  title: 'Example Post Title'
};

interface SubheadingDetail {
  id: string;
  title: string;
  content: string;
  parentSlug: string; // Assuming you want to keep track of the parent slug
}

const SubheadingShowPage = () => {
  const { parentSlug, childSlug } = useParams<{ parentSlug: string; childSlug: string }>(); // Get both parentSlug and childSlug from the URL
  const [subheading, setSubheading] = useState<SubheadingDetail | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSubheading = async () => {
      if (!parentSlug || !childSlug) {
        setError("Missing parent or child slug.");
        setLoading(false);
        return;
      }

      try {
        console.log(`Fetching subheading with parentSlug: ${parentSlug}, childSlug: ${childSlug}`);

        // Fetch child subheading data from Firestore using the substituted slugs
        const subheadingDoc = await getDoc(doc(fireDB, `blogPost/${parentSlug}/subheadings/${childSlug}`));

        if (subheadingDoc.exists()) {
          const data = subheadingDoc.data() as SubheadingDetail;
          console.log('Fetched data:', data); // Log the fetched data to ensure it's correct
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, ...restData } = data; // Destructure to remove the id field
          setSubheading({ id: subheadingDoc.id, ...restData }); // Add the id explicitly
        } else {
          setError("Subheading not found.");
        }
      } catch (err) {
        setError("Error fetching subheading.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSubheading();
  }, [parentSlug, childSlug]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <span className="text-xl text-gray-600">Loading...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <span className="text-xl text-red-600">{error}</span>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-6 px-1 sm:px-4 lg:px-5 bg-gray-50 flex flex-col">
    <div className="flex flex-wrap lg:flex-nowrap justify-between gap-8 flex-grow">
      {/* Sidebar with Suggestion List */}
      <div className="w-full lg:w-[20%] mt-5 lg:mt-0 order-last lg:order-first">
        <div className="mb-8">
          <SubSuggest />
        </div>
      </div>

      {/* Main Content Area */}
      <div className="w-full lg:w-[80%] lg:border-l border-gray-300 lg:pl-8">
        <section className="max-w-7xl mx-auto bg-white rounded-lg p-8 shadow-md">
          {/* Subheading Header Section */}
          <div className="mb-6">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-800 leading-tight">
              {subheading?.title}
            </h1>
          </div>

          {/* Divider */}
          <div className="border-b border-gray-300 mb-6" />

          {/* Subheading Content */}
          <div
            className="content text-base sm:text-lg md:text-xl text-gray-800 leading-relaxed"
            dangerouslySetInnerHTML={{ __html: subheading?.content || "No content available." }}
          />

          {/* Back Link */}
          <div className="mt-8 text-center">
            <a
              href={`/bloginfo/${parentSlug}`}
              className="px-6 py-2 text-sm text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition duration-200"
            >
              Back
            </a>
          </div>
        </section>
      </div>
    </div>
    <br /><br />
    <br />
    <Comment />
    <DisqusComments post={articlePost} />
  </div>
  );
};

export default SubheadingShowPage;

import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import myContext from "../../../Backend/Firebase/data/myContext"; // Adjust the import path if necessary

const Navbar = () => {
  const context = useContext(myContext);

  // Ensure context is defined before using it
  if (!context) {
    throw new Error("Navbar must be used within MyContextProvider");
  }

  const [openNav, setOpenNav] = useState(false);

  const admin = localStorage.getItem("admin");

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      {!admin && (
        <li>
          <Link
            to="/adminlogin"
            className="p-1 text-black" // Removed mode check
          >
            Admin Login
          </Link>
        </li>
      )}
    </ul>
  );

  return (
    <>
      <nav className="flex justify-between items-center p-4 bg-gray-200"> {/* Removed dynamic classes */}
        <div className="hidden lg:flex">{navList}</div>
        <Link to="/Blog" className="flex items-center">
          <span className="text-xl font-bold text-black"> {/* Removed dynamic classes */}
            ForumDE Blogs
          </span>
        </Link>

        <div className="flex items-center gap-4">
          <Link to="/dashboard">
            <img
              src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png"
              alt="avatar"
              className="w-10 h-10 border-2 rounded-full" // Removed dynamic classes
            />
          </Link>
          <button
            onClick={() => setOpenNav(!openNav)} // Removed toggleMode
            className="lg:hidden text-black" // Removed dynamic classes
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
      </nav>

      {openNav && <div className="lg:hidden">{navList}</div>}
    </>
  );
};

export default Navbar;

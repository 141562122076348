

const CourseAdvisors = () => {
  return (
    <div className="bg-orange-500 text-black text-sm sm:text-xl py-2 px-4 flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-6 fixed bottom-0 left-0 w-full">
      <span className="text-center sm:text-left">Call Our Course Advisors</span>
      <div className="flex flex-wrap justify-center space-x-5">
        <span className="text-center sm:text-left">
          <span >IND</span>
          <a href="tel:+91 98018 30173" className="hover:text-green-700 ">
            : +91-98018 30173{" "}
          </a>
          <a href="tel:+91-78709 70617" className="hover:text-green-700 ">
            / +91-78709 70617
          </a>
        </span>
        <span className="text-center sm:text-left">
          <span >US</span>
          <a href="tel:+1-252-490-1033" className="hover:text-green-700 ">
            : +1-252 490 1033
          </a>
        </span>
        <span className="text-center sm:text-left">
          <span >UK</span>
          <a href="tel:+44 121 3871751" className="hover:text-green-700 ">
            : +44-121 3871751
          </a>
        </span>
      </div>
    </div>
  );
};

export default CourseAdvisors;

import Details from '../../../Home_Page/Details/detail'
// import ContactUsButtons from '../../../Home_Page/ContactUs/ContactPage'
import Upcoming from '../../../Home_Page/UpcomingCourse/upcoming'
import CourseContainer from '../../../Home_Page/Course/Course'
import Programs from '../../../Home_Page/Programs/Programs'
import MyCertificate from '../../../Home_Page/Certificate/Certificate'
// import Mentors from '../../../Main_Components/Header/Navbar_Components/AboutUS/Mentor'
import Logo_Marquee from '../../../Home_Page/LogoMarquee/LogoMarquee'
import Video from '../../../Home_Page/Video/Video'
import FAQ from '../../../Home_Page/Faq/FaqPage'
import CustomMarquee from '../../../Home_Page/Marquee/Marquee'
import Company_Marquee from '../../../Home_Page/LogoMarquee/CompanyMarquee'
import Hike from '../../../Home_Page/Hike/hike'
import CallInfo from '../../../Home_Page/International Contact/Call-Info'
import Testimonials from '../../../Home_Page/Testimonials/testimonials'
import Popupchat from '../../../Home_Page/Popupchat/Popupchat'


const Home = () => {
  return (
   
        <>
        
          <div className=' space-y-1'>
          <Details />
          <CustomMarquee/>
          <Hike />
          {/* <ContactUsButtons /> */}
          <Upcoming />
          <CourseContainer />
          <br />
          <Programs />
          <br />
          <MyCertificate />
          {/* <Mentors /> */}
          
          <Logo_Marquee />
          <Company_Marquee/>
          
          <Testimonials/>
          <br/>
          <Video />
          <FAQ />
          <Popupchat />
          <CallInfo />
          </div>
        </>
     

  
  )
}

export default Home
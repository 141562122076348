import React from 'react';
import FastMarquee from 'react-fast-marquee';

const CustomMarquee: React.FC = () => {
  return (
    <div className='bg-yellow-200 text-xl' data-aos="fade-up" data-aos-delay="400">
      <FastMarquee>
        <div>
         || Registrations are open for all courses!! Enroll now. || Registrations are open for all courses!! Enroll now. || Registrations are open for all courses!! Enroll now. || Registrations are open for all courses!! Enroll now. || Registrations are open for all courses!! Enroll now. || Registrations are open for all courses!! Enroll now.

        </div>
      </FastMarquee>
    </div>
  );
}

export default CustomMarquee;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import logo from "../../../../assets/Header_LOGO.png";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { fireDB } from "../../../../Backend/Firebase/FirebaseConfigData";
import { Timestamp } from "firebase/firestore"; // Import Firebase Timestamp

// Define Updatedates component
const Updatedates = () => {
  const [courses, setCourses] = useState([
    { id: 1, courseName: "Azure", existingDate: "", updateDate: "" },
    { id: 2, courseName: "Python", existingDate: "", updateDate: "" },
    { id: 3, courseName: "SQL", existingDate: "", updateDate: "" },
  ]);

  // Function to format the Firebase Timestamp
  const formatDate = (timestamp: any) => {
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate();
      return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    }
    return "No Date Set";
  };

  // Fetch the course data from Firestore when the component mounts
  useEffect(() => {
    const fetchCourses = async () => {
      const courseDocs = [];
      for (const course of courses) {
        const courseDoc = doc(fireDB, "batchdetails", course.courseName);
        const docSnapshot = await getDoc(courseDoc);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const existingDate = data.date; // Fetch the 'date' field

          // Format the date if it's a Firebase Timestamp
          const formattedDate = formatDate(existingDate);

          courseDocs.push({
            ...course,
            existingDate: formattedDate, // Use the formatted date
          });
        } else {
          // If document does not exist, push course with empty existingDate
          courseDocs.push({
            ...course,
            existingDate: "Not Set",
          });
        }
      }
      setCourses(courseDocs);
    };

    fetchCourses();
  }, []);

  // Handle the change of date in the input field
  const handleDateChange = (id: number, value: string) => {
    setCourses((prevCourses) =>
      prevCourses.map((course) =>
        course.id === id ? { ...course, updateDate: value } : course
      )
    );
  };

  // Handle the update of the date in Firestore (create or update the document)
  const handleUpdateDate = async (courseName: string, updateDate: string) => {
    // Only proceed if an update date is selected
    if (!updateDate) {
      alert("Please select a new date to update.");
      return;
    }

    const courseRef = doc(fireDB, "batchdetails", courseName);

    try {
      // Convert the selected date to a Firebase Timestamp object
      const timestamp = Timestamp.fromDate(new Date(updateDate)); // Convert string date to Firebase Timestamp

      // Check if the document exists
      const docSnapshot = await getDoc(courseRef);

      if (!docSnapshot.exists()) {
        // If the document doesn't exist, create a new one with the provided date as a Timestamp
        await setDoc(courseRef, {
          date: timestamp, // Store the date as a Firebase Timestamp
        });
        alert(`${courseName} course created with date ${updateDate}`);
      } else {
        // If the document exists, update the 'date' field with the new Timestamp
        await setDoc(
          courseRef,
          {
            date: timestamp, // Store the updated date as a Firebase Timestamp
          },
          { merge: true } // Merge so it won't overwrite other fields
        );
        alert(`${courseName} course date has been updated to ${updateDate}`);
      }

      // Update the local state to reflect the changes
      setCourses((prevCourses) =>
        prevCourses.map((course) =>
          course.courseName === courseName ? { ...course, existingDate: updateDate } : course
        )
      );
    } catch (error) {
      console.error("Error updating date:", error);
      alert("There was an error updating the date. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-white to-blue-50 py-8">
      {/* Header */}
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 flex flex-col lg:flex-row justify-between items-center mb-12">
        <div className="mb-6 lg:mb-0">
          <img src={logo} alt="Logo" className="h-20 w-auto object-contain" />
        </div>
        <div className="text-center mb-6 lg:mb-0">
          <h1 className="text-5xl font-extrabold text-gray-800">ForumDE Admin</h1>
        </div>
      </div>

      <hr className="border-t-4 border-gray-300 mb-14" />

      <h1 className="text-5xl font-bold text-center text-gray-700 mb-15">Update Dates</h1>

      {/* Table Section */}
      <div className="container mx-auto px-4 max-w-7xl my-11 mb-20 bg-white shadow-xl rounded-xl overflow-x-auto">
        <table className="min-w-full table-auto text-sm text-gray-700">
          <thead className="bg-gradient-to-r from-blue-600 to-blue-500">
            <tr>
              <th className="text-3xl px-6 py-4 text-center text-white font-semibold">S.No</th>
              <th className="text-3xl px-6 py-4 text-center text-white font-semibold">Course Name</th>
              <th className="text-3xl px-6 py-4 text-center text-white font-semibold">Existing Date</th>
              <th className="text-3xl px-6 py-4 text-center text-white font-semibold">Updated Date</th>
              <th className="text-3xl px-6 py-4 text-center text-white font-semibold">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y-4 divide-gray-300">
            {courses.map((course, index) => (
              <tr key={course.id} className="transition duration-300 ease-in-out hover:bg-gray-50">
                <td className="text-2xl px-6 py-4 text-center text-gray-800 font-semibold">{index + 1}</td>
                <td className="text-2xl px-6 py-4 text-center text-gray-800 font-semibold">{course.courseName}</td>
                <td className="text-2xl px-6 py-4 text-center text-gray-800 font-semibold">
                  {course.existingDate || "No Date Available"}
                </td>
                <td className="px-6 py-4 text-center">
                  <div className="flex items-center gap-4 justify-center">
                    <input
                      title="Update Date"
                      type="date"
                      className="text-xl border border-gray-300 rounded px-2 py-1"
                      value={course.updateDate}
                      onChange={(e) => handleDateChange(course.id, e.target.value)}
                    />
                    <span className="text-2xl text-gray-800 font-medium">{course.updateDate}</span>
                  </div>
                </td>
                <td className="px-6 py-4 text-center">
                  <button
                    className="text-xl font-bold text-white bg-blue-500 px-4 py-2 rounded"
                    onClick={() => handleUpdateDate(course.courseName, course.updateDate)}
                  >
                    Update Date
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Updatedates;

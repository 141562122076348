// App.tsx

// import Contactpage from "./Additional_components/Additional/Contact_Us/contactpage";   <Route path="/Contact" element={<Contactpage />} />
import PrivacyPolicy from "./Additional_components/Additional/Private_Policy/policy";
import CancellationRefundPolicy from "./Additional_components/Additional/Refund_Page/refund";
import TermsAndConditions from "./Additional_components/Additional/TermsandCondition/terms";
import Page from "./Additional_components/Additional/Underconstruction/underconstruction";
import Updatedates from "./Admin_Panel/Dashboard/EditHomepage/UpdateDate/Update-Dates";
import UpdateGif from "./Admin_Panel/Dashboard/EditHomepage/UpdateGif/Update-Gif";
import UpdateValuesTable from "./Admin_Panel/Dashboard/EditHomepage/UpdateHike/Update-Value";
import Updatetestinomials from "./Admin_Panel/Dashboard/EditHomepage/UpdateTestimonials/updatetestinomials";
import Mainpage_home from "./Admin_Panel/Dashboard/EditHomepage/mainpage_home";
import Createnew from "./Admin_Panel/Dashboard/Update/CreateNew/Createnew";
import SubheadingUpdate from "./Admin_Panel/Dashboard/Update/UpdateSubhading/SubheadingUpdate";
import UpdateBlog from "./Admin_Panel/Dashboard/Update/update";
import ViewCorporateData from "./Admin_Panel/Dashboard/ViewFormsData/View-CorporateTrainig/ViewCorporateTraining";
import ViewHireFormUS from "./Admin_Panel/Dashboard/ViewFormsData/View-HireFromUS/ViewHirefromUS";
import ViewDBAForm from "./Admin_Panel/Dashboard/ViewFormsData/ViewDBAForm/View-DBA-Form";
import ViewFormData from "./Admin_Panel/Dashboard/ViewFormsData/ViewFormData";
import ViewHDForm from "./Admin_Panel/Dashboard/ViewFormsData/ViewHDForm/View-HD-Form";
import CreateBlog from "./Admin_Panel/Dashboard/createBlog/createBlog";
import CreateSubheading from "./Admin_Panel/Dashboard/createSubheading/subheading";
import Dashboard from "./Admin_Panel/Dashboard/dashboard/dashboard";
import AdminLogin from "./Admin_Panel/adminLogin/AdminLogin";
import { ProtectedRouteForAdmin } from "./Backend/AdminProtect/ProtectedRouteForAdmin";
import MyState from "./Backend/Firebase/data/myState";
import SubheadingShowPage from "./Blog/All Blog Display/Sugestions/ShowSuggestionPage";
import BlogInfo from "./Blog/All Blog Display/blogInfo/BlogInfo";
import Suggest from "./Blog/All Blog Display/blogInfo/Sugest";
import MainBlogpage from "./Blog/All Blog Display/mainpage";
import PySpark from "./FrontEnd/Components/Content_Table/PySpark_Page";
import DBAProgram from "./Home_Page/Programs/DBAProgram";
import HDProgram from "./Home_Page/Programs/HDProgram";
import { Footer } from "./Main_Components/Footer/footer";
import Home from "./Main_Components/Header/Home/home";
import Aboutus from "./Main_Components/Header/Navbar_Components/AboutUS/Aboutus";
import Testimonials from "./Main_Components/Header/Navbar_Components/Achiver_wall/achiverwall";
import CorporateTraining from "./Main_Components/Header/Navbar_Components/CorporateTraining/corporatetraining";
import HireFromUsForm from "./Main_Components/Header/Navbar_Components/HireFromUS/hireus";
import JobPage from "./Main_Components/Header/Navbar_Components/Job/JobPage";
import StudyAbroad from "./Main_Components/Header/Navbar_Components/StudyAbroad/studyabroad";
import Navbar from "./Main_Components/Header/navbar";
// Import MyState provider
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";

// import Updatedates from "./ForumDE_Article/admin/EditHomepage/UpdateDate/Update-Dates";

function App() {
  return (
    <>
      <MyState>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/corporate-training" element={<CorporateTraining />} />
          <Route path="/hirefromus" element={<HireFromUsForm />} />
          <Route path="/studyabroad" element={<StudyAbroad />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Private" element={<PrivacyPolicy />} />
          <Route path="/Terms" element={<TermsAndConditions />} />

          <Route path="/UnderWork" element={<Page />} />
          <Route path="/PySpark" element={<PySpark />} />
          <Route path="/Job" element={<JobPage />} />
          <Route
            path="/programs/doctorate-in-business-administration"
            element={<DBAProgram />}
          />
          <Route path="/programs/honorary-doctorate" element={<HDProgram />} />
          <Route path="/Blog" element={<MainBlogpage />} />
          <Route path="/blog" element={<MainBlogpage />} />
          <Route path="/bloginfo/:id" element={<BlogInfo />} />
          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/bloginfo/:parentSlug" element={<Suggest />} />
          <Route
            path="/bloginfo/:parentSlug/:childSlug"
            element={<SubheadingShowPage />}
          />

          <Route
            path="/createblog"
            element={
              <ProtectedRouteForAdmin>
                <CreateBlog />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/update-ocassion"
            element={
              <ProtectedRouteForAdmin>
                <UpdateGif />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/edit-dates"
            element={
              <ProtectedRouteForAdmin>
                <Updatedates />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/View-Form-Data/View-DBA-Form"
            element={
              <ProtectedRouteForAdmin>
                <ViewDBAForm />
              </ProtectedRouteForAdmin>
            }
          />

          <Route
            path="/View-Form-Data"
            element={
              <ProtectedRouteForAdmin>
                <ViewFormData />
              </ProtectedRouteForAdmin>
            }
          />

          <Route
            path="/View-Form-Data/View-HD-Form"
            element={
              <ProtectedRouteForAdmin>
                <ViewHDForm />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/View-Form-Data/View-Hire-From-US"
            element={
              <ProtectedRouteForAdmin>
                <ViewHireFormUS />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/View-Form-Data/View-Corporate-Training"
            element={
              <ProtectedRouteForAdmin>
                <ViewCorporateData />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/edit-values"
            element={
              <ProtectedRouteForAdmin>
                <UpdateValuesTable />
              </ProtectedRouteForAdmin>
            }
          />

          <Route
            path="/edit-testinomials"
            element={
              <ProtectedRouteForAdmin>
                <Updatetestinomials />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/edithomepage"
            element={
              <ProtectedRouteForAdmin>
                <Mainpage_home />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/create-subheading/:parentSlug"
            element={
              <ProtectedRouteForAdmin>
                <CreateSubheading />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/updateblog/:id"
            element={
              <ProtectedRouteForAdmin>
                <UpdateBlog />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/updatesubheading/:id"
            element={
              <ProtectedRouteForAdmin>
                <SubheadingUpdate />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/createnew/:id"
            element={
              <ProtectedRouteForAdmin>
                <Createnew />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRouteForAdmin>
                <Dashboard />
              </ProtectedRouteForAdmin>
            }
          />
          <Route path="/Refund" element={<CancellationRefundPolicy />} />
        </Routes>
        <Toaster />
        <Footer />
      </MyState>
    </>
  );
}

export default App;
